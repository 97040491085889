import { ImBundleTargetLocation } from '@types'

export const isImBundleTargetLocation = (valueToCheck: unknown): valueToCheck is ImBundleTargetLocation => {
  const isPlainObject = !!valueToCheck && typeof valueToCheck === 'object' && !Array.isArray(valueToCheck)
  if (isPlainObject) {
    const objToCheck = valueToCheck as Record<string, unknown>
    const hasNecessaryProps =
      objToCheck.area !== undefined && objToCheck.coordinates !== undefined && objToCheck.name !== undefined
    if (hasNecessaryProps) {
      return (
        typeof objToCheck.area === 'number' &&
        Array.isArray(objToCheck.coordinates) &&
        typeof objToCheck.name === 'string'
      )
    }
  }
  return false
}
