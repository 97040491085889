import { Autocomplete, Chip, TextField } from '@mui/material'

const CountriesSelect = ({
  countries,
  value,
  isLoading = false,
  onChange,
  formType = '',
  error = null,
  label,
  disabled = false,
}) => {
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      filterSelectedOptions
      options={countries || []}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={countries ? value : []}
      onChange={(ev, values) => onChange(values)}
      data-testid={`${formType}_countries_select`}
      loading={isLoading}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            size="small"
            sx={{ '&.MuiChip-sizeSmall': { my: '1px' } }}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={!!error}
          helperText={error}
          name={`${formType}_countries`}
          sx={{
            backgroundColor: 'background.default',
          }}
        />
      )}
    />
  )
}

export default CountriesSelect
