import { IM_FORM_DEFAULT_VALUES } from '@components/form/im-form-fields'
import { IndicatorMessageFormValues } from '@types'
import { createContext, useContext, useState, ReactNode, useCallback } from 'react'

type IMFormValues = IndicatorMessageFormValues | null

type TIMContext = {
  imFormValues: IMFormValues
  setIMFormValues: (values: IMFormValues) => void
  updateIMFormValues: (values: Partial<IMFormValues>) => void
  isValid: boolean
  setIsValid: (isValid: boolean) => void
}

const IMContext = createContext<TIMContext>({} as TIMContext)

type Props = {
  children: ReactNode
}

export const IMContextProvider = ({ children }: Props) => {
  const [imFormValues, setIMFormValues] = useState<IMFormValues>(IM_FORM_DEFAULT_VALUES)
  const [isValid, setIsValid] = useState<boolean>(false)
  const updateIMFormValues = useCallback(
    (values: Partial<IMFormValues>) => {
      setIMFormValues(Object.assign({}, structuredClone(imFormValues), values))
    },
    [imFormValues]
  )

  return (
    <IMContext.Provider
      value={{
        imFormValues,
        setIMFormValues,
        updateIMFormValues,
        isValid,
        setIsValid,
      }}
    >
      {children}
    </IMContext.Provider>
  )
}

export const useIMContext = () => {
  return useContext(IMContext)
}
