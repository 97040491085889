import { Button, Stack, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import I18n from 'i18next'

type ButtonBaseProps = {
  submit: {
    label: string
    disabled?: boolean
    loading?: boolean
    onClick?: (e) => void
  }
  back: {
    onClick: (e) => void
    disabled?: boolean
  }
  cancel: {
    onClick: (e) => void
    disabled?: boolean
  }
  enabledPolygonTools?: false
}

type ButtonWithPolygonEnabledProps = Omit<ButtonBaseProps, 'enabledPolygonTools'> & {
  enabledPolygonTools: true
  reset: {
    onClick: (e) => void
  }
  save: {
    onClick: (e) => void
  }
}

export const Buttons = (props: ButtonBaseProps | ButtonWithPolygonEnabledProps) => {
  const { submit, back, cancel, enabledPolygonTools } = props
  return (
    <Stack direction={'row'} sx={{ py: 2 }} justifyContent={'space-between'}>
      <Box flex={1}>
        <Button color="secondary" onClick={back.onClick} disabled={back.disabled} data-testid="back">
          {I18n.t('indicator_message.buttons.back')}
        </Button>
      </Box>
      {enabledPolygonTools && (
        <Box flex={1} textAlign={'center'}>
          <Button color="secondary" onClick={props.reset.onClick} data-testid="reset" sx={{ mr: 1 }}>
            {I18n.t('indicator_message.buttons.reset')}
          </Button>
          <Button color="primary" onClick={props.save.onClick} data-testid="save">
            {I18n.t('indicator_message.buttons.save_polygon')}
          </Button>
        </Box>
      )}
      <Box flex={1} textAlign={'right'}>
        <Button
          color="secondary"
          sx={{ mr: 1 }}
          onClick={cancel.onClick}
          disabled={cancel.disabled}
          data-testid="cancel"
        >
          {I18n.t('indicator_message.buttons.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={submit.onClick}
          loading={submit.loading || submit.disabled}
          data-testid="submit"
        >
          {submit.label}
        </LoadingButton>
      </Box>
    </Stack>
  )
}
