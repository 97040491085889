import { ImTargetLocation } from '@types'

export const isImTargetLocation = (value: unknown): value is ImTargetLocation => {
  if (typeof value !== 'object' || value === null) return false

  const obj = value as Record<string, unknown>

  return (
    typeof obj.fullName === 'string' &&
    typeof obj.latitude === 'number' &&
    typeof obj.longitude === 'number' &&
    typeof obj.name === 'string' &&
    typeof obj.radius === 'string' &&
    typeof obj.radiusFormatted === 'string'
  )
}
