import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Bundle, ReviewBundleData } from '@src/client/types'

const useUnsetFreezedBundle = () => {
  const queryClient = useQueryClient()

  const mutationFn = async (): Promise<void> => {
    await queryClient.setQueryData(['GET_REVIEW_DATA'], (data: ReviewBundleData): ReviewBundleData => {
      const bundles: Bundle[] = [...data.bundles]
      const bundleFreezed = bundles.find((bundleData: Bundle) => !!bundleData.freezed)
      if (bundleFreezed) {
        delete bundleFreezed.freezed
      }
      return {
        ...data,
        bundles,
      }
    })
  }

  return useMutation<void>({
    mutationKey: ['UNSET_FREEZED_MESSAGE_BUNDLE'],
    mutationFn,
  })
}

export default useUnsetFreezedBundle
