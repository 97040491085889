import { isEmpty, merge, pick } from 'lodash'
import moment from 'moment'
import { transformLocations } from './common'
import { postJSON, patchJSON } from '@common/http'
import { FORM_MODE_REVIEW } from '@src/client/pages/im/constants/form-mode/form-mode'

export const ERRORS_SAVING = 'ERRORS_SAVING'
export const REQUEST_SAVE = 'REQUEST_SAVE'
export const SUCCESSFULLY_SAVED = 'SUCCESSFULLY_SAVED'

export const saveMessage = (imFormValues, bundleId) => {
  return (dispatch, getState) => {
    const state = getState().indicatorMessage
    const {
      save: { inProgress },
    } = state

    if (inProgress) return Promise.reject()

    dispatch(requestSave())

    return performSubmit(state, imFormValues, bundleId)
      .then(() => {
        dispatch(successfullySaved())
      })
      .catch(() => {
        dispatch(errorsSaving())
      })
  }
}

export const requestSave = () => ({
  type: REQUEST_SAVE,
})

export const errorsSaving = () => ({
  type: ERRORS_SAVING,
})

export const successfullySaved = () => ({
  type: SUCCESSFULLY_SAVED,
})

const performSubmit = (state, imFormValues, bundleId) => {
  const { formMode } = state

  if (formMode === FORM_MODE_REVIEW) {
    return patchJSON(`/api/message_bundles/${bundleId}`, { ...transformMessage(state, imFormValues) })
  } else {
    return postJSON('/api/message_bundles', { ...transformMessage(state, imFormValues) })
  }
}

const transformMessage = (state, imFormValues) => {
  const {
    onlyNews,
    type,
    industries,
    subject,
    sourceForContent,
    sourceForCopyright,
    sourceUrl,
    score,
    validUntil,
    content,
    noValidity,
  } = imFormValues

  const transformedMessageData = {
    onlyNews,
    type,
    industries,
    subject,
    sourceContent: sourceForContent,
    sourceCopyright: sourceForCopyright,
    hyperlinks: transformHyperlinks(sourceUrl),
    score,
    validUntil: transformDate(validUntil),
    noValidity,
    body: content,
  }

  if (type === 'country') {
    transformedMessageData.indicatorId = onlyNews ? null : (state.country.data.indicator || {}).id
    transformedMessageData.country = transformCountry(state, imFormValues.onlyNews)
  } else if (type === 'location') {
    transformedMessageData.indicatorId = state.location.data.indicator.id
    transformedMessageData.location = transformLocations(state, {
      areas: ['latitude', 'longitude', 'name'],
      polygons: ['name'],
    })
  } else if (type === 'business_partner') {
    const {
      supplier: {
        data: {
          alertForm: { selectedIndicator, searches: alertSearches },
          newsForm: { searches: newsSearches },
        },
        priorityForm,
      },
    } = state

    transformedMessageData.indicatorId = onlyNews ? null : selectedIndicator && selectedIndicator.id
    transformedMessageData.businessPartner = transformSupplier(state, imFormValues.onlyNews)
    transformedMessageData.businessPartnerSearches = { indicatorMessageSearches: alertSearches, newsSearches }
    transformedMessageData.formPriorityReverse = priorityForm === 'newsForm'
  }

  return {
    message: {
      ...transformedMessageData,
    },
  }
}

const transformCountry = (state, onlyNews) => {
  const {
    country: {
      data: { indicatorMessageCountry, newsCountries },
    },
  } = state
  return {
    indicatorMessageCountries:
      onlyNews || isEmpty(indicatorMessageCountry) ? [] : transformCountryObjects([indicatorMessageCountry]),
    newsCountries: transformCountryObjects(newsCountries),
  }
}

const transformCountryObjects = (countries) => {
  return countries.map((country) => ({ code: country.code }))
}

const mapSuppliers = (suppliers) => {
  const attributes = ['id', 'name', 'city', 'countryCode', 'coreInstance', 'streetAndNumber', 'zipcode', 'memberIds']

  return suppliers.map((supplier) => pick(merge({}, supplier, supplier.address), attributes))
}

const transformSupplier = (state, onlyNews) => {
  const {
    supplier: {
      data: {
        alertForm: { suppliers: indicatorMessageBusinessPartners },
        newsForm: { suppliers: newsBusinessPartners },
      },
    },
  } = state

  return {
    indicatorMessageBusinessPartners: onlyNews ? [] : mapSuppliers(indicatorMessageBusinessPartners),
    newsBusinessPartners: mapSuppliers(newsBusinessPartners),
  }
}

const transformHyperlinks = (hyperlink1) => {
  return [hyperlink1].filter((item) => !isEmpty(item))
}

const transformDate = (date) => {
  return date ? moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') : null
}
