import moment from 'moment'
import { ImData, ImIndicator, Indicator, IndicatorMessageFormValues } from '@types'
import getIMIndicatorSubtypesFromReceivedData from '@pages/im/utils/getIMIndicatorSubtypesFromReceivedData'

export const parseImDataToFormValues = (imData: ImData): IndicatorMessageFormValues | null => {
  if (!imData.messageBundle) {
    const im = imData.message || imData.copyMessage
    if (im) {
      const selectedIndicator = im.indicator && transformImIndicatorToIndicatorType(im.indicator)
      const indicatorSubtypes = getIMIndicatorSubtypesFromReceivedData(im.indicator, im.indicatorSubtypes)
      return {
        id: im.id,
        onlyNews: im.isNews,
        type: im.type,
        industries: im.industries || [],
        subject: im.subject,
        sourceForContent: im.sourceContent,
        sourceForCopyright: im.sourceCopyright,
        sourceUrl: im.hyperlink1,
        content: im.body,
        validUntil: im.validUntil ? new Date(moment(im.validUntil, 'DD.MM.YYYY').format('MM.DD.YYYY')) : null,
        noValidity: !im.validUntil,
        selectedIndicator,
        indicatorSubtypes: indicatorSubtypes,
      }
    } else {
      return null
    }
  } else {
    const [{ subject, body, type, industries, hyperlink1, sourceContent, sourceCopyright, indicator }] =
      imData.messageBundle.messages

    const alertMessage = imData.messageBundle.messages.find((message) => message.isNews === false)
    const validUntil = alertMessage && alertMessage.validUntil
    const noValidity = alertMessage && !validUntil
    const onlyNews = !alertMessage
    const indicatorSubtypes = getIMIndicatorSubtypesFromReceivedData(
      imData.messageBundle.messages[0]?.indicator,
      imData.messageBundle.messages[0]?.indicatorSubtypes
    )

    return {
      id: imData.messageBundle.id,
      subject,
      content: body,
      type,
      industries,
      sourceUrl: hyperlink1,
      sourceForContent: sourceContent,
      sourceForCopyright: sourceCopyright,
      validUntil: validUntil ? new Date(moment(validUntil, 'DD.MM.YYYY').format('MM.DD.YYYY')) : null,
      noValidity: noValidity || false,
      onlyNews,
      indicatorSubtypes,
      selectedIndicator: indicator && transformImIndicatorToIndicatorType(indicator),
    }
  }
}

const transformImIndicatorToIndicatorType = (indicatorSchemaData: ImIndicator): Indicator => {
  const { id, rmCode, name, indicatorType, subtypes } = indicatorSchemaData
  return {
    id,
    rmCode,
    name,
    indicatorType,
    subtypes: subtypes ?? [],
  }
}
