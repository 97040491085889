import { ImTypeEnum } from '@enums'
import { ReactNode, useLayoutEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

type Props = {
  formContext: UseFormReturn
  children: ReactNode
}

export const IMFormFields = ({ formContext, children }: Props) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = formContext

  const onlyNews = watch('onlyNews')
  const type = watch('type')
  const noValidity = watch('noValidity')
  const validUntil = watch('validUntil')

  useLayoutEffect(() => {
    if (noValidity && validUntil !== null) {
      setValue('validUntil', null)
    }
  }, [noValidity, validUntil])

  useLayoutEffect(() => {
    if (noValidity && errors['validUntil']) {
      clearErrors(['validUntil'])
    }
  }, [noValidity, errors])

  useLayoutEffect(() => {
    if (onlyNews) {
      setValue('noValidity', true)
    }
  }, [onlyNews])

  useLayoutEffect(() => {
    if (type === ImTypeEnum.LOCATION) {
      setValue('noValidity', false)
    }
  }, [type])

  return children
}
