export const enum QueryStrategyValuesEnum {
  ALL = 'all',
  SUBJECT_BODY = 'subject_body',
  ID_IN_ADMIN = 'id_in_admin',
}

export enum ValidityQueryValuesEnum {
  ALL = 'all',
  EXPIRE_TODAY = 'expire_today',
  VALID = 'valid',
}

export enum MessageTypeQueryValuesEnum {
  ALL = 'all',
  INDICATOR_MESSAGE = 'indicator_message',
  NEWS = 'news',
}
