import { useQuery } from '@tanstack/react-query'
import { stringify } from 'qs'
import set from 'lodash/set'
import type { ReviewFormValues, GetReviewBundleSchema, ReviewBundleData } from '@types'
import { ValidityQueryValuesEnum, MessageTypeQueryValuesEnum, ImTypeEnum, QueryStrategyValuesEnum } from '@enums'
import { fetch, displayError } from '@services'
import { camelizeKeys } from 'humps'
import i18n from '@src/client/i18n'
import { processKeywordsSearch } from '@common/entities/messages/utils'

export const DEFAULT_DATA: ReviewBundleData = {
  page: 1,
  perPage: 50,
  totalPages: 0,
  totalResults: 0,
  bundles: [],
}

const transformReqParams = (params: ReviewFormValues): string => {
  const { keywords, query_strategy, message_subtype, validity, message_type, indicator_id, page, sort } = params
  const obj = {}

  if (keywords) set(obj, 'keywords', processKeywordsSearch(keywords))
  if (query_strategy && query_strategy !== QueryStrategyValuesEnum.ALL) set(obj, 'query_strategy', query_strategy)
  if (message_subtype && message_subtype !== ImTypeEnum.ALL) set(obj, 'message_subtype', message_subtype)
  if (validity && validity !== ValidityQueryValuesEnum.ALL) set(obj, 'validity', validity)
  if (message_type && message_type !== MessageTypeQueryValuesEnum.ALL) set(obj, 'message_type', message_type)
  if (indicator_id) set(obj, 'indicator_id', indicator_id)

  set(obj, 'page', page)
  set(obj, 'sort', sort)

  return stringify(obj, { arrayFormat: 'brackets' })
}

const transformData = (data: GetReviewBundleSchema | null): ReviewBundleData => {
  if (!data) return DEFAULT_DATA

  const transformedData: ReviewBundleData = camelizeKeys<GetReviewBundleSchema>(data)

  return transformedData ?? DEFAULT_DATA
}

const queryFn = async (params: ReviewFormValues): Promise<ReviewBundleData> => {
  let data: GetReviewBundleSchema | null = null
  const queryString = transformReqParams(params)
  const path = `/api/message_bundles.json?${queryString}`
  const res = await fetch(path)

  if (res.ok) {
    data = await res.json()
  } else {
    displayError(i18n.t('reviews.errors.fetch_failure'))
  }

  return transformData(data) ?? { ...DEFAULT_DATA }
}

const useGetReviewBundles = (params: ReviewFormValues) => {
  return useQuery<ReviewBundleData>({
    initialData: DEFAULT_DATA,
    queryKey: ['GET_REVIEW_DATA'],
    queryFn: () => queryFn(params),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetReviewBundles
