import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import I18n from 'i18next'
import { decamelize } from 'humps'

import { useGetIndustries } from '@api'
import { confirm } from '@common/components/alert'
import { Bundle, ReviewBundleData } from '@types'
import CommonTable from '@common/components/table'
import buildTableColumns from '@pages/review/utils/buildTableColumns'
import { useApproveBundle, useDeleteBundle } from '@pages/review/api'

type ReviewTableOwnProps = {
  data: ReviewBundleData
  error: Error | null
  loading: boolean
  handlePageChanged: (page: number) => Promise<void>
  handleSortChanged: (s: string) => Promise<void>
}

const Table = (props: ReviewTableOwnProps) => {
  const [state, setState] = useState<{ expanded: object; freezedBundle: Bundle | null }>({
    expanded: {},
    freezedBundle: null,
  })
  const { mutate: approveBundle } = useApproveBundle()
  const { mutate: deleteBundle } = useDeleteBundle()
  const {
    loading,
    data: { bundles, totalResults, page },
    error,
    handleSortChanged,
    handlePageChanged,
  } = props

  const history = useHistory()
  const { industryMap } = useGetIndustries()

  useEffect(() => {
    setState({
      ...state,
      expanded: {},
    })
  }, [page])

  const handleRowExpanded = useCallback(
    (id) => {
      setState({
        ...state,
        expanded: {
          ...state.expanded,
          [id]: !state.expanded[id],
        },
      })
    },
    [state, setState]
  )

  const sortChanged = useCallback(
    (sortField, sortOrder) => {
      const sortParameter = `${sortOrder === 'desc' ? '-' : ''}${decamelize(sortField)}`
      handleSortChanged(sortParameter)
    },
    [handleSortChanged]
  )

  const getBundleType = (messages) => {
    if (messages.length === 1) {
      return messages[0].isNews ? 'news' : 'alert'
    } else {
      return 'alert & news'
    }
  }

  const onApproveClick = (bundle: Bundle) => {
    confirm({
      title: I18n.t('reviews.alerts.approve.title'),
      body: I18n.t('reviews.alerts.approve.body', { messages: getBundleType(bundle.messages) }),
      isFullWidth: false,
    }).then(
      () => {
        approveBundle(bundle.id)
      },
      () => null
    )
  }

  const onDeleteClick = (bundle: Bundle) => {
    confirm({
      title: I18n.t('reviews.alerts.delete.title'),
      body: I18n.t('reviews.alerts.delete.body', { messages: getBundleType(bundle.messages) }),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(
      () => {
        deleteBundle(bundle.id)
      },
      () => null
    )
  }

  const columns = buildTableColumns({
    onApprove: onApproveClick,
    onDelete: onDeleteClick,
    handleRowExpanded: handleRowExpanded,
    expanded: state.expanded,
    history,
    industryMap,
  })
  return (
    <div data-testId="review-table">
      <CommonTable
        data={bundles}
        loading={loading}
        columns={columns}
        pageChanged={handlePageChanged}
        sortChanged={sortChanged}
        emptyLabel={I18n.t('history.table.messages.empty')}
        perPage={50}
        totalSize={totalResults}
        page={page}
        errors={error ? [error] : []}
      />
    </div>
  )
}

export default Table
