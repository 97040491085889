import { Indicator } from '@types'

export const isIndicatorSubtypesValueValid = (
  indicator: Indicator | null,
  indicatorSubtypes: null | string[]
): boolean => {
  if (indicator && (indicator.subtypes ?? []).length > 0) {
    return indicatorSubtypes === null || !!indicatorSubtypes.length
  }
  return true
}
