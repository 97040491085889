import { Autocomplete, Chip, SxProps, TextField, Theme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { t } from 'i18next'
import { IndicatorSubtype } from '@types'
import { useIndicatorSubtypesMap } from '@api'

type IndicatorSubtypeSelectProps = {
  subtypes: IndicatorSubtype[]
  value: string[] | null
  onChange: (values: string[] | null) => void
  disabled: boolean
  error?: string
  sx?: SxProps<Theme>
}

const notSpecifiedOption: IndicatorSubtype = { code: null, name: 'Not Specified' }

export const SubtypeSelect = ({
  subtypes,
  value = [],
  onChange,
  error,
  disabled,
  sx = {},
}: IndicatorSubtypeSelectProps) => {
  const label = t(`indicator_message.placeholders.${subtypes?.length === 0 ? 'n_a' : 'subindicator'}`)
  const { data: subtypesMap } = useIndicatorSubtypesMap()
  const [isNotSpecifiedSelected, setIsNotSpecifiedSelected] = useState<boolean>(value === null)

  const options: IndicatorSubtype[] = useMemo(() => {
    if (!subtypes) {
      return []
    } else if (subtypes.length) {
      return [...subtypes, notSpecifiedOption]
    } else {
      return subtypes
    }
  }, [subtypes])

  const selectedOptions = useMemo(() => {
    if (value === null) {
      return [notSpecifiedOption]
    } else if (value.length > 0) {
      return value
        .map((subtypeCode: string) => {
          return subtypesMap?.get(subtypeCode)
        })
        .filter((subtypeData) => !!subtypeData)
    }
    return []
  }, [value, subtypesMap, subtypes])

  const renderTags = useCallback(
    (selectedOptions: IndicatorSubtype[], getTagProps) => {
      return selectedOptions.map((option: IndicatorSubtype, index: number) => {
        return (
          <Chip
            key={option.code}
            variant="outlined"
            size="small"
            sx={{ '&.MuiChip-sizeSmall': { my: '1px' } }}
            label={option.name}
            {...getTagProps({ index: index })}
          />
        )
      })
    },
    [value]
  )

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        fullWidth
        label={label}
        error={!!error}
        helperText={error}
        name="subtypes"
        sx={{
          backgroundColor: 'background.default',
        }}
      />
    ),
    [label, error]
  )

  const handleValueChange = useCallback(
    (ev, selectedOptions) => {
      const value = selectedOptions.map((option) => option.code)
      const selectedOptionsContainNotSpecified = selectedOptions.find((subtype) => subtype.code === null)

      if (isNotSpecifiedSelected || !selectedOptionsContainNotSpecified) {
        onChange(value.filter((code) => !!code))
        setIsNotSpecifiedSelected(false)
      } else {
        onChange(null)
        setIsNotSpecifiedSelected(true)
      }
    },
    [onChange, isNotSpecifiedSelected, setIsNotSpecifiedSelected]
  )

  const isOptionEqualToValue = useCallback(
    (option: IndicatorSubtype, selectedIndicator: IndicatorSubtype) => option.code === selectedIndicator.code,
    []
  )

  const getOptionLabel = useCallback(({ name }: IndicatorSubtype) => name, [])

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      filterSelectedOptions
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      value={selectedOptions}
      onChange={handleValueChange}
      data-testid="subtypes_select"
      renderTags={renderTags}
      renderInput={renderInput}
      sx={sx}
    />
  )
}
