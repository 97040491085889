import { useCallback } from 'react'
import I18n from 'i18next'

import {
  Box,
  Stack,
  Paper,
  Button,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  Skeleton,
} from '@mui/material'

import Tags from '@common/components/tags'
import { FormItem, SubtypeSelect } from '@pages/im/components/im-type-specific-form/components/shared'
import { processSearchTerm } from '@pages/im/components/im-type-specific-form/components/bp-im-form/helpers'

import SearchLabels from '../search-labels/search-labels'
import SupplierSearchResult from '../supplier-search-result/supplier-search-result'
import CitiesSelect from '../cities-select/cities-select'
import CountriesSelect from '../countries-select/countries-select'
import { Bp, IndicatorsData, Indicator } from '@types'

import * as styles from '@pages/im/components/im-type-specific-form/components/bp-im-form/bp-im-form.scss'
import { useIndicatorsRelatedDataMaps } from '@api'
import { ImFormModeEnum } from '@src/client/enums'

const t = (name) => I18n.t(`indicator_message.form.supplier.${name}`)
const placeholdersT = (name) => I18n.t(`indicator_message.placeholders.${name}`)

type SupplierDataFormProps = {
  formChanged: (name: string, value: string | string[] | Indicator | null) => void
  formSearch: () => void
  removeSupplier: (supplier: Bp) => void
  action: ImFormModeEnum
  indicators?: IndicatorsData
  indicatorsLoading: boolean
  selectedIndicator?: Indicator | null
  selectedIndicatorSubtypes: string[] | null
  countries?: object[]
  clearForm: () => void
  formData: {
    includeNames?: object[]
    excludeNames?: object[]
    countries?: object[]
    cities?: object[]
  }
  suppliers?: Bp[]
  suppliersLoading: boolean
  errors?: Record<string, string>
  searches?: object[]
  removeSearch: (searchId: string) => void
  name?: 'alert' | 'news'
  onlyNews?: boolean
}

const SupplierDataForm = ({
  formChanged,
  indicators,
  selectedIndicator,
  indicatorsLoading,
  errors,
  selectedIndicatorSubtypes,
  formData,
  formSearch,
  countries,
  suppliersLoading,
  searches,
  removeSearch,
  clearForm,
  removeSupplier,
  suppliers,
  name,
  onlyNews,
  action,
}: SupplierDataFormProps) => {
  const formNameNormalised = name ?? 'alert'
  const {
    data: { indicatorsDataMap },
  } = useIndicatorsRelatedDataMaps()
  const onSelectChange = useCallback(
    (name) => (value) => {
      formChanged(name, value)
    },
    [formChanged]
  )

  const onTagsChange = useCallback(
    (name) => (value) => {
      const processedValue = processSearchTerm(value)
      formChanged(name, processedValue)
    },
    [formChanged]
  )

  const onIndicatorsChange = useCallback(
    (event) => {
      const { value } = event.target
      const selectedIndicator = indicatorsDataMap?.get(value) ?? null
      formChanged('indicatorSubtypes', [])
      formChanged('selectedIndicator', selectedIndicator)
    },
    [indicatorsDataMap, formChanged]
  )

  const getFormBackgroundColor = useCallback(
    (theme) => {
      if (formNameNormalised === 'news') {
        return 'background.accent'
      }
      return theme.palette.mode === 'light' ? '#e8ebf0' : '#222222'
    },
    [formNameNormalised]
  )
  const isOnHistoryPage = action === ImFormModeEnum.FORM_MODE_EDIT

  return (
    <Stack
      flex={1}
      noValidate
      component="form"
      autoComplete="off" // disable countries auto fill
      onSubmit={(ev) => ev.preventDefault()}
      sx={{ maxWidth: '800px' }}
      data-testid="supplier_form"
    >
      <Typography variant="h5" component="h3" sx={{ mb: 2 }} textAlign={'center'}>
        {t(`titles.${formNameNormalised}`)}
      </Typography>
      <Stack
        component={Paper}
        id={`supplier_${formNameNormalised}_form`}
        elevation={4}
        sx={{
          overflow: 'hidden',
          width: '100%',
          flexShrink: 0,
          flexGrow: 1,
          position: 'relative',
          backgroundColor: getFormBackgroundColor,
        }}
        p={3}
        gap={2}
      >
        {name === 'news' && !onlyNews ? (
          <>
            <Skeleton variant="rectangular" animation={false} sx={{ height: '37px', borderRadius: '4px' }} />
            <Skeleton variant="rectangular" animation={false} sx={{ height: '37px', borderRadius: '4px' }} />
          </>
        ) : (
          <>
            <FormItem label={t('labels.indicator')}>
              <TextField
                select
                required
                fullWidth
                sx={{ backgroundColor: 'background.default' }}
                value={selectedIndicator?.rmCode ?? ''}
                label={placeholdersT('indicator')}
                onChange={(ev) => onIndicatorsChange(ev)}
                InputProps={
                  indicatorsLoading
                    ? {
                        endAdornment: (
                          <InputAdornment position="start" sx={{ pr: 1 }}>
                            <CircularProgress color="secondary" size={25} />
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
                error={!!errors?.noIndicator}
                helperText={errors?.noIndicator}
                data-testid={`supplier_indicator_select`}
                disabled={isOnHistoryPage}
              >
                {(indicators || []).map((option) => (
                  <MenuItem
                    key={option.rmCode}
                    value={option.rmCode}
                    data-testid={`supplier_indicator_${option.rmCode}`}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormItem>
            <FormItem label={t('labels.subindicator')}>
              <SubtypeSelect
                value={selectedIndicatorSubtypes}
                subtypes={selectedIndicator?.subtypes ?? []}
                onChange={onSelectChange('indicatorSubtypes')}
                disabled={!selectedIndicator?.subtypes?.length}
                error={errors?.noIndicatorSubtypes}
              />
            </FormItem>
          </>
        )}
        <FormItem label={t('labels.search')}>
          <Tags
            onChange={onTagsChange('includeNames')}
            value={formData.includeNames || []}
            disabled={isOnHistoryPage}
            isClearable={false}
            data-testid={`${formNameNormalised}_include_names`}
            onEnterPressed={formSearch}
            height={37}
            maxHeight={150}
          />
        </FormItem>
        <FormItem label={t('labels.is_not')}>
          <Tags
            onChange={onTagsChange('excludeNames')}
            //placeholder={t('placeholders.is_not')}
            value={formData.excludeNames || []}
            isClearable={false}
            disabled={isOnHistoryPage}
            data-testid={`${formNameNormalised}_exclude_names`}
            onEnterPressed={formSearch}
            height={37}
            maxHeight={150}
          />
        </FormItem>
        <FormItem label={t('labels.country')}>
          <CountriesSelect
            disabled={isOnHistoryPage}
            countries={countries}
            value={formData.countries || []}
            onChange={onSelectChange('countries')}
            label={placeholdersT('countries')}
            formType={formNameNormalised}
          />
        </FormItem>
        <FormItem label={t('labels.city')}>
          <CitiesSelect
            disabled={isOnHistoryPage}
            formType={formNameNormalised}
            value={formData.cities || []}
            onChange={onSelectChange('cities')}
          />
        </FormItem>
        <Box textAlign={'right'}>
          <Button
            onClick={formSearch}
            type="button"
            color="secondary"
            disabled={suppliersLoading || isOnHistoryPage}
            data-testid={`${formNameNormalised}_search`}
          >
            {t(suppliersLoading ? 'info.loading' : 'buttons.search')}
          </Button>
        </Box>
        <Stack flex={1} justifyContent={'end'} data-testid={`${formNameNormalised}_suppliers_result_count`}>
          <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 1 }} gap={1} textAlign={'right'}>
            <SearchLabels
              formType={formNameNormalised}
              disabled={isOnHistoryPage}
              searches={searches}
              removeSearch={removeSearch}
              suppliers={suppliers ?? []}
            />
            <Box alignSelf={'end'}>
              <Button
                color="error"
                variant="text"
                type="button"
                onClick={clearForm}
                className={styles.removeAll}
                data-testid={`${formNameNormalised}_remove_all`}
                disabled={isOnHistoryPage}
              >
                {t('buttons.remove_all')}
              </Button>
            </Box>
          </Stack>
          <SupplierSearchResult
            formType={formNameNormalised}
            suppliers={suppliers}
            suppliersLoading={suppliersLoading}
            removeSupplier={removeSupplier}
            disabled={isOnHistoryPage}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SupplierDataForm
