import { useQuery } from '@tanstack/react-query'

import { fetch } from '@services'
import type { ShiftIndicator } from '@pages/shift-management/types'

export type GetIndicatorsData = {
  bp_indicators: ShiftIndicator[]
  location_indicators: ShiftIndicator[]
} | null

const getIndicators = async (): Promise<GetIndicatorsData> => {
  const res = await fetch('/api/v2/buckets/indicators')

  let data = null
  if (res.ok) data = await res.json()
  return data
}

export const useGetIndicators = () => {
  return useQuery<GetIndicatorsData>({
    queryKey: ['SHIFT_MANAGEMENT_GET_INDICATORS'],
    queryFn: getIndicators,
    gcTime: 0,
  })
}
