import { ImIndicator } from '@types'

const getIMIndicatorSubtypesFromReceivedData = (
  indicatorData: ImIndicator | null,
  indicatorSubtypesReceived: string[] = []
) => {
  const indicatorHasSubtypes = indicatorData !== null && (indicatorData.subtypes ?? []).length > 0
  return indicatorHasSubtypes ? (indicatorSubtypesReceived.length > 0 ? indicatorSubtypesReceived : null) : []
}

export default getIMIndicatorSubtypesFromReceivedData
