// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container--lWK3T{padding:20px 0}.slider-bottom-labels--foSxo{display:flex;justify-content:space-between;margin-bottom:20px;color:#757575}.text-color--ovdVY{color:#757575}`, "",{"version":3,"sources":["webpack://./src/client/pages/shift-management/components/hits_distribution/reassign_modal/reassign_modal.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CAGF,6BACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,aAAA,CAGF,mBACE,aAAA","sourcesContent":[".slider-container {\n  padding: 20px 0;\n}\n\n.slider-bottom-labels {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  color: #757575;\n}\n\n.text-color {\n  color: #757575;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `slider-container--lWK3T`;
export { _1 as "slider-container" };
export var sliderContainer = `slider-container--lWK3T`;
var _2 = `slider-bottom-labels--foSxo`;
export { _2 as "slider-bottom-labels" };
export var sliderBottomLabels = `slider-bottom-labels--foSxo`;
var _3 = `text-color--ovdVY`;
export { _3 as "text-color" };
export var textColor = `text-color--ovdVY`;
export default ___CSS_LOADER_EXPORT___;
