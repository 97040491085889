import { ImTypeEnum } from '@enums'
import type { EditMsgReqBody, EditMsgReqData, EditMsgReqMessage, ImPageState, IndicatorMessageFormValues } from '@types'
import { decamelizeKeys } from 'humps'
import { transformSupplier } from '../transform-supplier'

const getIndicatorSubtypesFromState = (state: ImPageState, imType: ImTypeEnum | null): string[] | null => {
  switch (imType) {
    case ImTypeEnum.BUSINESS_PARTNER:
      return state.supplier.data.alertForm.indicatorSubtypes
    case ImTypeEnum.COUNTRY:
      return state.country.data.indicatorSubtypes
    case ImTypeEnum.LOCATION:
      return state.location.data.indicatorSubtypes
    default:
      return []
  }
}

export const getMsgReqBody = (imFormValues: IndicatorMessageFormValues, state: ImPageState): EditMsgReqBody => {
  const {
    subject,
    type,
    onlyNews,
    sourceForContent: sourceContent,
    sourceForCopyright: sourceCopyright,
    content: body,
    sourceUrl,
    industries,
    indicatorSubtypes,
    selectedIndicator,
  } = imFormValues

  const {
    country: { data: countryData },
    supplier: { priorityForm },
  } = state

  const message: EditMsgReqMessage = {
    subject,
    sourceContent,
    sourceCopyright,
    body,
    hyperlinks: [sourceUrl],
    industries,
    formPriorityReverse: priorityForm === 'newsForm',
    indicatorSubtypes: indicatorSubtypes ?? [],
  }

  if (type === ImTypeEnum.COUNTRY && onlyNews) {
    message.country = { newsCountries: countryData.newsCountries.map(({ code }) => ({ code })) }
  } else if (type === ImTypeEnum.BUSINESS_PARTNER) {
    message.businessPartner = transformSupplier(state, onlyNews)
  }
  if (selectedIndicator?.rmCode) {
    message.indicatorId = selectedIndicator.rmCode
  }
  message.indicatorSubtypes = getIndicatorSubtypesFromState(state, type)

  return decamelizeKeys<EditMsgReqData>({ message })
}
