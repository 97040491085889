import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fetch, displayError } from '@services'
import { HistoryMessagesData } from '@src/client/types'

const useDeleteMessage = () => {
  const queryClient = useQueryClient()

  const mutationFn = async (messageId: number): Promise<void> => {
    const res = await fetch(
      `/api/messages/${messageId}.json`,
      {
        method: 'DELETE',
      },
      0
    )

    if (!res.ok) {
      setTimeout(() => {
        displayError('Could delete message this time.')
      })
      return Promise.reject()
    }

    await queryClient.setQueryData(['GET_HISTORY_MESSAGES'], (data: HistoryMessagesData): HistoryMessagesData => {
      return {
        ...data,
        messages: data.messages.filter((messageData) => messageData.id !== messageId),
      }
    })
  }
  return useMutation<void, void, number>({
    mutationKey: ['DELETE_HISTORY_MESSAGE'],
    mutationFn,
  })
}

export default useDeleteMessage
