import { ImTypeEnum, QueryStrategyValuesEnum, ValidityQueryValuesEnum, MessageTypeQueryValuesEnum } from '@enums'

export const initValues = {
  review: {
    keywords: '',
    query_strategy: QueryStrategyValuesEnum.SUBJECT_BODY,
    message_subtype: ImTypeEnum.ALL,
    validity: ValidityQueryValuesEnum.ALL,
    message_type: MessageTypeQueryValuesEnum.ALL,
    indicator_id: null,
    page: 1,
  },
  history: {
    keywords: '',
    query_strategy: QueryStrategyValuesEnum.SUBJECT_BODY,
    message_subtype: ImTypeEnum.ALL,
    validity: ValidityQueryValuesEnum.ALL,
    message_type: MessageTypeQueryValuesEnum.ALL,
    indicator_id: null,
    from_last_two_years: false,
    page: 1,
  },
}
