import { useSearchParams, Form } from '@riskmethods/rm-front-end'
import { useCallback, useEffect } from 'react'

import { PageCont, TopCont, BottomCont } from '@common/layout'
import { ImFilters } from '@components'
import { ReviewFormValues } from '@types'
import { ImFilterPageNameEnum } from '@enums'
// import AlertMessage from '@common/components/alert_message'

import { initFilterConfig } from './utils/constants'
import Table from './components/table'
import { useGetReviewBundles } from './api'
import { useClearDataOnLeavingReviewPage } from '@hooks'

const ReviewPage = () => {
  const { searchParams, syncSearchParams } = useSearchParams(initFilterConfig)
  const { indicator_id } = searchParams.object as ReviewFormValues
  const getReviewBundles = useGetReviewBundles(searchParams.object as ReviewFormValues) // Refetch if coming from other page)

  useClearDataOnLeavingReviewPage()

  const fetchBundlesData = useCallback(async () => {
    await getReviewBundles.refetch()
    window.scrollTo(0, 0)
  }, [getReviewBundles])

  const handleFiltersFormSubmit = useCallback(
    async (data: object) => {
      await syncSearchParams({ ...data, page: 1 }, false)
      fetchBundlesData()
    },
    [fetchBundlesData, syncSearchParams]
  )

  const handeSortChanged = useCallback(
    async (sort) => {
      await syncSearchParams({ ...searchParams.object, sort, page: 1 }, false)
      fetchBundlesData()
    },
    [searchParams.object, fetchBundlesData]
  )

  const handlePageChanged = useCallback(
    async (page) => {
      await syncSearchParams({ ...searchParams.object, page }, false)
      fetchBundlesData()
    },
    [searchParams.object, fetchBundlesData]
  )

  useClearDataOnLeavingReviewPage()

  useEffect(() => {
    if (!getReviewBundles.isFetched) {
      fetchBundlesData()
    }
  }, [])

  return (
    <div data-testId="review-page-wrapper">
      <Form config={{ defaultValues: searchParams.object }} onSubmit={handleFiltersFormSubmit}>
        {(formContext) => {
          const { reset, setValue } = formContext

          return (
            <PageCont>
              <TopCont>
                {/* {map(errors, (error) => (
                <AlertMessage key={error} message={error} />
              ))} */}
                <ImFilters
                  pageName={ImFilterPageNameEnum.REVIEW}
                  reset={reset}
                  changeFilterValue={setValue}
                  selectedIndicatorId={indicator_id}
                  isLoading={getReviewBundles.isFetching}
                />
              </TopCont>
              <BottomCont>
                <Table
                  data={getReviewBundles.data}
                  handlePageChanged={handlePageChanged}
                  handleSortChanged={handeSortChanged}
                  loading={getReviewBundles.isFetching}
                  error={getReviewBundles.error}
                />
              </BottomCont>
            </PageCont>
          )
        }}
      </Form>
    </div>
  )
}

export default ReviewPage
