import { has } from 'lodash'
import I18n from 'i18next'
import { Country, ImPageStateCountry, ImValidationResult, IndicatorMessageFormValues } from '@types'
import { Camelized } from 'humps'
import { imValidationValidResult } from '../constants'
import { isIndicatorSubtypesValueValid } from './indicator_subtypes'

const validateIndicatorMessagesAndNews = (
  imFormValues: IndicatorMessageFormValues,
  indicatorMessageCountry: Country | Record<string, never>,
  newsCountries: Country[]
): ImValidationResult => {
  const { selectedIndicator, indicatorSubtypes } = imFormValues
  const indicatorValid = has(selectedIndicator, 'rmCode') && has(selectedIndicator, 'name')
  const indicatorSubtypesValid = isIndicatorSubtypesValueValid(selectedIndicator, indicatorSubtypes)
  const indicatorMessageValid = has(indicatorMessageCountry, 'id') && has(indicatorMessageCountry, 'name')
  const partiallyValid = indicatorValid !== indicatorMessageValid
  const newsValid = newsCountries.length > 0
  const isValid = partiallyValid ? false : (indicatorValid && indicatorMessageValid) || newsValid
  const errors = {
    ...(indicatorValid ? {} : { indicator: I18n.t('common.messages.validation.required') }),
    ...(indicatorMessageValid ? {} : { indicatorMessage: I18n.t('common.messages.validation.required') }),
    ...(newsValid ? {} : { news: I18n.t('common.messages.validation.required') }),
    ...(indicatorSubtypesValid ? {} : { indicatorSubtypes: I18n.t('common.messages.validation.required') }),
  }
  return isValid ? { valid: isValid, errors: {} } : { valid: isValid, errors }
}

const validateNews = (
  newsCountries: Camelized<Country>[],
  imFormValues: IndicatorMessageFormValues
): ImValidationResult => {
  const newsValid = newsCountries.length > 0
  const { selectedIndicator, indicatorSubtypes } = imFormValues
  const indicatorValid = has(selectedIndicator, 'rmCode') && has(selectedIndicator, 'name')
  const indicatorSubtypesValid = isIndicatorSubtypesValueValid(selectedIndicator, indicatorSubtypes)
  const message = {
    ...(newsValid ? {} : { news: I18n.t('common.messages.validation.required') }),
    ...(indicatorValid ? {} : { indicator: I18n.t('common.messages.validation.required') }),
    ...(indicatorSubtypesValid ? {} : { indicatorSubtypes: I18n.t('common.messages.validation.required') }),
  }
  const isFormValid = newsValid && indicatorValid
  return isFormValid
    ? imValidationValidResult
    : {
        valid: isFormValid,
        errors: message,
      }
}

export const validationCountry = (
  data: ImPageStateCountry,
  imFormValues: IndicatorMessageFormValues
): ImValidationResult => {
  const { indicatorMessageCountry, newsCountries } = data
  const { onlyNews } = imFormValues
  return onlyNews
    ? validateNews(newsCountries, imFormValues)
    : validateIndicatorMessagesAndNews(imFormValues, indicatorMessageCountry, newsCountries)
}
