import { Button, CircularProgress } from '@mui/material'
import { filter, isEmpty, compact, uniq, uniqBy, map, sortBy } from 'lodash'
import I18n from 'i18next'

import Modal from '@common/components/modal'
import { Recipient } from '@types'
import { useCallback } from 'react'

type ShowRecipientsModalProps = {
  recipients?: Recipient[] | null
  isOpen: boolean
  onClose: (coreMessageId?: number, messageId?: number) => void
}

const ShowRecipientsModal = ({ onClose, isOpen, recipients }: ShowRecipientsModalProps) => {
  const notEmptyRecipients = useCallback((recipients: Recipient[]) => {
    const uniqRecipients = uniqBy(recipients, 'email')
    const recipientsList = filter(uniqRecipients, (recipient) => {
      return !isEmpty(compact(Object.values(recipient)))
    })
    return sortBy(recipientsList, ['fullName', 'companyName'])
  }, [])

  const notifiedCustomers = useCallback(() => {
    return compact(uniq(map(recipients, 'companyName'))).sort()
  }, [recipients])

  const handleOnClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal
      title={I18n.t('indicator_message.modals.show_recipients.title')}
      footer={
        <Button color="secondary" onClick={handleOnClose}>
          {I18n.t('common.close')}
        </Button>
      }
      onClose={onClose}
      isOpen={!!isOpen}
    >
      <span>{I18n.t('indicator_message.modals.show_recipients.body.notified_customers')}</span>
      {recipients === null || recipients === undefined ? (
        <ol>
          <CircularProgress size={30} sx={{ mt: 1 }} />
        </ol>
      ) : (
        <ol>
          {!isEmpty(recipients) ? (
            notifiedCustomers().map((companyName, index) => <li key={index}>{companyName}</li>)
          ) : (
            <span className="text-secondary">
              <i>{I18n.t('indicator_message.modals.show_recipients.body.no_notified_customers')}</i>
            </span>
          )}
        </ol>
      )}
      <span>{I18n.t('indicator_message.modals.show_recipients.body.list_of_recipients')}</span>
      {recipients === null || recipients === undefined ? (
        <ol>
          <CircularProgress size={30} sx={{ mt: 1 }} />
        </ol>
      ) : (
        <ul>
          {!isEmpty(recipients) ? (
            notEmptyRecipients(recipients).map(({ firstName, lastName, email, companyName }) => (
              <li key={email}>
                {firstName} {lastName} {`<${email}>`} - {companyName}
              </li>
            ))
          ) : (
            <span className="text-secondary">
              <i>{I18n.t('indicator_message.modals.show_recipients.body.no_recipients')}</i>
            </span>
          )}
        </ul>
      )}
    </Modal>
  )
}

export default ShowRecipientsModal
