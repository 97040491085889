// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duns--kJ74U{width:110px}.name--c8P3A{min-width:170px;color:inherit;hyphens:auto;word-break:break-word}.name-cont--_TGW6{padding-right:10px}.name-cont--_TGW6:hover a.google-button--HEIg5{opacity:1;pointer-events:all}.btn-anchor--sCRal{position:relative}a.google-button--HEIg5{position:absolute;left:.3rem;top:50%;opacity:0;pointer-events:none;transform:translateY(-50%);transition:opacity .2s ease-in-out}.address--UElfX{min-width:150px}.search-name--YTYkJ{min-width:200px}.native-search-name--TqMqn{min-width:220px}`, "",{"version":3,"sources":["webpack://./src/client/pages/bp/bp-page.scss"],"names":[],"mappings":"AAAA,aACE,WAAA,CAGF,aACE,eAAA,CAEA,aAAA,CAEA,YAAA,CACA,qBAAA,CAGF,kBACE,kBAAA,CACA,+CACE,SAAA,CACA,kBAAA,CAIJ,mBACE,iBAAA,CAGF,uBACE,iBAAA,CACA,UAAA,CACA,OAAA,CAEA,SAAA,CACA,mBAAA,CAEA,0BAAA,CAEA,kCAAA,CAGF,gBACE,eAAA,CAGF,oBACE,eAAA,CAGF,2BACE,eAAA","sourcesContent":[".duns {\n  width: 110px;\n}\n\n.name {\n  min-width: 170px;\n\n  color: inherit;\n\n  hyphens: auto;\n  word-break: break-word;\n}\n\n.name-cont {\n  padding-right: 10px; // leave space for the icon\n  &:hover a.google-button {\n    opacity: 1;\n    pointer-events: all;\n  }\n}\n\n.btn-anchor {\n  position: relative;\n}\n\na.google-button {\n  position: absolute; // make the icon not increase the row height\n  left: 0.3rem;\n  top: 50%;\n\n  opacity: 0;\n  pointer-events: none;\n\n  transform: translateY(-50%);\n\n  transition: opacity 0.2s ease-in-out;\n}\n\n.address {\n  min-width: 150px;\n}\n\n.search-name {\n  min-width: 200px;\n}\n\n.native-search-name {\n  min-width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
export var duns = `duns--kJ74U`;
export var name = `name--c8P3A`;
var _1 = `name-cont--_TGW6`;
export { _1 as "name-cont" };
export var nameCont = `name-cont--_TGW6`;
var _2 = `google-button--HEIg5`;
export { _2 as "google-button" };
export var googleButton = `google-button--HEIg5`;
var _3 = `btn-anchor--sCRal`;
export { _3 as "btn-anchor" };
export var btnAnchor = `btn-anchor--sCRal`;
export var address = `address--UElfX`;
var _4 = `search-name--YTYkJ`;
export { _4 as "search-name" };
export var searchName = `search-name--YTYkJ`;
var _5 = `native-search-name--TqMqn`;
export { _5 as "native-search-name" };
export var nativeSearchName = `native-search-name--TqMqn`;
export default ___CSS_LOADER_EXPORT___;
