import { useEffect } from 'react'
import { Grid, Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Form } from '@riskmethods/rm-front-end'
import I18n from 'i18next'

import {
  ContentTextArea,
  IndustriesMultiSelect,
  NoValidityToggle,
  OnlyNewsToggle,
  SourceForContentInput,
  SourceForCopyrightInput,
  SourceUrlInput,
  SubjectInput,
  TypeSelect,
  ValidUntilDatePicker,
  IMFormFields,
  IM_FORM_DEFAULT_VALUES,
} from '@components/form/im-form-fields'

import { useIMContext } from '@hooks'
import type { IndicatorMessageFormValues } from '@types'
import type { ImTypeEnum } from '@enums'

type Props = {
  handleAccept: (data: object) => void
  handleCancel: () => void
  acceptBtnLabel: string
  submitOnAccept?: boolean
  isLoading?: boolean
  disableTypeSelect?: boolean
  disableValidUntilDatePicker?: boolean
  disableNoValidityToggle?: boolean
  disableOnlyNewsToggle?: boolean
}

export const ImForm = ({
  handleAccept,
  handleCancel,
  acceptBtnLabel,
  submitOnAccept = false,
  isLoading = false,
  disableTypeSelect = false,
  disableValidUntilDatePicker = false,
  disableNoValidityToggle = false,
  disableOnlyNewsToggle = false,
}: Props) => {
  const { imFormValues, setIMFormValues, setIsValid } = useIMContext()

  return (
    <Form
      config={{ defaultValues: IM_FORM_DEFAULT_VALUES, mode: 'onChange' }}
      onSubmit={handleAccept}
      disabled={isLoading}
    >
      {(formContext) => {
        const { watch, getValues, reset, trigger, formState } = formContext

        useEffect(() => {
          return () => {
            setIMFormValues(getValues() as IndicatorMessageFormValues)
          }
        }, [])

        useEffect(() => {
          if (formState.defaultValues?.id !== IM_FORM_DEFAULT_VALUES.id) {
            trigger()
          }
        }, [formState.defaultValues])

        useEffect(() => {
          setIsValid(formState.isValid)
        }, [formState.isValid])

        useEffect(() => {
          reset(imFormValues as IndicatorMessageFormValues)
        }, [imFormValues])

        const onlyNews = watch('onlyNews')
        const type: ImTypeEnum | null = watch('type')
        const noValidity = watch('noValidity')

        return (
          <IMFormFields formContext={formContext}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={4}>
                <Stack gap={4}>
                  <TypeSelect onlyNews={onlyNews} disabled={disableTypeSelect} />
                  <IndustriesMultiSelect />
                  <SourceForContentInput />
                  <SourceForCopyrightInput />
                  <SourceUrlInput />
                  <Stack data-testid="datepicker_check" direction="row" alignItems="flex-end" gap={3}>
                    <ValidUntilDatePicker noValidity={noValidity} disabled={disableValidUntilDatePicker} />
                    <NoValidityToggle onlyNews={onlyNews} type={type} disabled={disableNoValidityToggle} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack gap={4}>
                  <SubjectInput />
                  <ContentTextArea />
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <OnlyNewsToggle type={type} disabled={disableOnlyNewsToggle} />
                    <Button data-testid="cancel" color="secondary" onClick={handleCancel}>
                      {I18n.t('indicator_message.buttons.cancel')}
                    </Button>
                    <LoadingButton
                      data-testid="accept"
                      type={submitOnAccept ? 'submit' : 'button'}
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      onClick={!submitOnAccept ? handleAccept : undefined}
                    >
                      {acceptBtnLabel}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </IMFormFields>
        )
      }}
    </Form>
  )
}
