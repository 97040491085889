import { NewsNameColumnFormatted } from '@common/table_formatters/news_name_column_formatter'

export const nameFormatter = (_, message) => {
  const hasIndicatorSelected = !!message.indicatorId

  if (message.isNews && hasIndicatorSelected) {
    return <NewsNameColumnFormatted indicatorId={message.indicatorId} />
  } else {
    return message.name
  }
}
