import { uniq, find } from 'lodash'
import { Stack, Button, Tooltip, IconButton, CircularProgress } from '@mui/material'
import ArticleIcon from '@mui/icons-material/ContentPasteSearch'
import I18n from 'i18next'

import { useIMContext } from '@hooks'

import { geometryFormatter as messageGeometryFormatter } from '@common/table_formatters/geometry_formatter'
import { showMoreFormatter } from '@common/table_formatters/show_more_formatter/show_more_formatter'
import { NewsNameColumnFormatted } from '@common/table_formatters/news_name_column_formatter'

export const nameFormatter = (_, bundle) => {
  const isBundleOfOneNewsMessage = bundle.messages.length === 1 && bundle.messages[0].isNews
  const hasIndicatorSelected = !!bundle.indicatorId

  if (isBundleOfOneNewsMessage && hasIndicatorSelected) {
    return <NewsNameColumnFormatted indicatorId={bundle.indicatorId} />
  } else {
    return bundle.messages.map(({ name }) => name).join(' & ')
  }
}

export const alertTargetNameFormatter = (_, bundle, _rowIndex, formatExtraData) => {
  const message = find(bundle.messages, { isNews: false })
  if (!message) return null
  return showMoreFormatter(
    message.targets.map((m) => m.name),
    _rowIndex,
    formatExtraData
  )
}

export const newsTargetNameFormatter = (_, bundle, _rowIndex, formatExtraData) => {
  const message = find(bundle.messages, { isNews: true })
  if (!message) return null
  return showMoreFormatter(
    message.targets.map((m) => m.name),
    _rowIndex,
    formatExtraData
  )
}

export const geometryFormatter = (_, bundle) =>
  uniq(bundle.messages.map((message) => messageGeometryFormatter(null, message))).map((geometry, index) => (
    <div key={index}>{geometry}</div>
  ))

const EditFormatter = ({ history, bundle }) => {
  const { setIMFormValues } = useIMContext()

  return (
    <Stack direction="column" gap={1}>
      <div>
        <Button
          component="a"
          variant="text"
          href={`/review/edit?id=${bundle.id}`}
          edge="start"
          color="info"
          sx={{
            '&:hover': {
              color: 'info.main',
            },
          }}
          disabled={!!bundle.freezed}
          onClick={(e) => {
            e.preventDefault()
            setIMFormValues(null)
            history.push(`/review/edit?id=${bundle.id}`)
          }}
        >
          {I18n.t('common.reviews_table.buttons.edit')}
        </Button>
      </div>
      {bundle.hitId ? (
        <div>
          <IconButton
            edge="start"
            color="info"
            sx={{
              '&:hover': {
                color: 'info.main',
              },
            }}
            onClick={() => {
              window.open(`/article_history_overview?id=${bundle.hitId}`, '_blank')
            }}
            data-testid="article_history_overview_icon"
          >
            <Tooltip title="Article History Overview">
              <ArticleIcon fontSize="small" id={`row_${bundle.id}`} />
            </Tooltip>
          </IconButton>
        </div>
      ) : null}
    </Stack>
  )
}

export const actionsFormatter = (history) => (_cell, bundle, _rowIndex, formatExtraData) => {
  if (bundle.freezed) {
    return <CircularProgress size={25} />
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <EditFormatter history={history} bundle={bundle} />
        <Button variant="text" onClick={() => formatExtraData.onApprove(bundle)}>
          {I18n.t('reviews.buttons.approve')}
        </Button>
        <Button color="error" variant="text" onClick={() => formatExtraData.onDelete(bundle)}>
          {I18n.t('reviews.buttons.delete')}
        </Button>
      </div>
    )
  }
}
