import { Bp, ImPageState, ImTargetBp } from '@types'
import { Camelized } from 'humps'
import { merge, pick } from 'lodash'

const mapSuppliers = (suppliers: ImTargetBp[] | Camelized<Bp>[]): Camelized<Bp>[] => {
  const attributes = ['id', 'name', 'city', 'countryCode', 'streetAndNumber', 'zipcode', 'memberIds']

  return suppliers.map((supplier) => pick(merge({}, supplier, supplier.address), attributes)) as Camelized<Bp>[]
}

export const transformSupplier = (state: ImPageState, onlyNews: boolean) => {
  const {
    supplier: {
      data: {
        alertForm: { suppliers: indicatorMessageBusinessPartners },
        newsForm: { suppliers: newsBusinessPartners },
      },
    },
  } = state

  return {
    indicatorMessageBusinessPartners: onlyNews ? [] : mapSuppliers(indicatorMessageBusinessPartners as ImTargetBp[]),
    newsBusinessPartners: mapSuppliers(newsBusinessPartners as ImTargetBp[]),
  }
}
