import { configureStore, combineReducers } from '@reduxjs/toolkit'
import reduceReducers from 'reduce-reducers'
import merge from 'lodash/merge'

import { reducer as bpReducer } from '@pages/bp/reducers'
import { reducer as eListReducer } from '@pages/e-list/reducers'
import { default as indicatorMessageReducer } from '@pages/im/reducers'
import { reducer as individualSettingsReducer } from '@pages/individual-settings/reducers'
import { reducer as shiftManagementReducer } from '@pages/shift-management/reducers'
import { reducer as worldMapReducer } from '@pages/world-map/reducers'

import { SET_IM_INIT_STATE } from '@pages/im/actions'
import imInitialState from '@pages/im/reducers/initial_state'

import { initialState as filter } from '@pages/world-map/reducers/filter'
import { initialState as layers } from '@pages/world-map/reducers/layers'
import { initialState as visibility } from '@pages/world-map/reducers/visibility'
import { initialState as loading } from '@pages/world-map/reducers/loading'

export const rootReducer = reduceReducers(
  combineReducers({
    bp: bpReducer,
    elist: eListReducer,
    indicatorMessage: indicatorMessageReducer,
    individualSettings: individualSettingsReducer,
    shiftManagement: shiftManagementReducer,
    worldMap: worldMapReducer,
  }),
  (state, action) => {
    switch (action.type) {
      case SET_IM_INIT_STATE:
        return {
          ...state,
          indicatorMessage: merge(
            {},
            imInitialState,
            {
              location: {
                filter,
                layers,
                visibility,
                loading,
              },
            },
            action.data
          ),
        }
      default:
        return state
    }
  }
)

export const initStore = () => {
  return configureStore({
    devTools:
      process.env.NODE_ENV === 'development'
        ? {
            name: 'RIC',
          }
        : false,
    reducer: rootReducer,
  })
}
