import moment from 'moment'
import I18n from 'i18next'
import { useCallback } from 'react'

import { Stack, Tooltip, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ScheduleIcon from '@mui/icons-material/Schedule'
import PeopleIcon from '@mui/icons-material/People'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import ArticleIcon from '@mui/icons-material/ContentPasteSearch'

import { useIMContext } from '@hooks'
import { MOMENT_DATE_FORMAT as DATE_FORMAT } from '@common/components/date_picker'

const ActionsFormatter = ({ row, formatExtraData }) => {
  const { setIMFormValues } = useIMContext()

  const messageEditable = row.coreMessageId !== null
  const disableShowRecipientsButton = row.coreMessageId === null

  const validityEditable =
    !row.isNews && (!row.validUntil || moment().isSameOrBefore(moment(row.validUntil, DATE_FORMAT), 'day'))

  const handleOpenEditValidityDateModal = useCallback(
    (ev) => {
      ev.preventDefault()
      formatExtraData.rowEvents.openEditValidityDateModal(row.id)
    },
    [formatExtraData, row]
  )

  const handleOpenShowRecipientsModal = useCallback(
    (ev) => {
      ev.preventDefault()
      formatExtraData.rowEvents.openShowRecipientsModal(row.coreMessageId, row.id)
    },
    [formatExtraData, row]
  )

  const showNewsDeletionConfirmationModal = useCallback(
    (ev) => {
      ev.preventDefault()
      formatExtraData.rowEvents.showNewsDeletionConfirmationModal(row.id)
    },
    [formatExtraData, row]
  )

  return (
    <div key={row.id}>
      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <IconButton
            component="a"
            href={`/history/edit?id=${row.id}`}
            edge="start"
            color="info"
            sx={{
              '&:hover': {
                color: 'info.main',
              },
            }}
            onClick={(e) => {
              e.preventDefault()
              setIMFormValues(null)
              formatExtraData.history.push(`/history/edit?id=${row.id}`)
            }}
            disabled={!messageEditable}
            data-testid="edit"
          >
            <Tooltip title={I18n.t('indicator_message.headers.general_tooltip_edit')}>
              <EditIcon fontSize="small" id={`row_edit_${row.id}`} />
            </Tooltip>
          </IconButton>
          <IconButton
            color="info"
            onClick={handleOpenEditValidityDateModal}
            disabled={!validityEditable}
            data-testid="edit_validity_date"
          >
            <Tooltip title={I18n.t('indicator_message.headers.general_tooltip_time')}>
              <ScheduleIcon fontSize="small" id={`row_time_${row.id}`} />
            </Tooltip>
          </IconButton>
        </Stack>
        <Stack direction="row" gap={1}>
          <IconButton
            edge="start"
            color="info"
            onClick={handleOpenShowRecipientsModal}
            disabled={disableShowRecipientsButton}
            data-testid="show_recipients"
          >
            <Tooltip title={I18n.t('indicator_message.headers.general_tooltip_users')}>
              <PeopleIcon fontSize="small" id={`row_users_${row.id}`} />
            </Tooltip>
          </IconButton>
          <Tooltip title={I18n.t('indicator_message.headers.general_tooltip_copy')}>
            <IconButton
              component="a"
              href={`/history/copy?id=${row.id}`}
              color="info"
              sx={{
                '&:hover': {
                  color: 'info.main',
                },
              }}
              onClick={(e) => {
                e.preventDefault()
                setIMFormValues(null)
                formatExtraData.history.push(`/history/copy?id=${row.id}`)
              }}
              data-testid="copy_message"
            >
              <ContentCopyIcon fontSize="small" id={`row_${row.id}`} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="row" gap={1}>
          <IconButton
            edge="start"
            color="error"
            onClick={showNewsDeletionConfirmationModal}
            disabled={!row.isNews}
            data-testid="delete_news"
          >
            {/* put tooltip inside so it is not wrapped around possibly disabled button */}
            <Tooltip title={I18n.t('indicator_message.headers.general_tooltip_delete')}>
              <DeleteIcon fontSize="small" id={`row_delete_${row.id}`} />
            </Tooltip>
          </IconButton>
          {row.hitId ? (
            <IconButton
              color="info"
              sx={{
                '&:hover': {
                  color: 'info.main',
                },
              }}
              onClick={() => {
                window.open(`/article_history_overview?id=${row.hitId}`, '_blank')
              }}
              data-testid="article_history_overview_icon"
            >
              <Tooltip title="Article History Overview">
                <ArticleIcon fontSize="small" id={`row_${row.id}`} />
              </Tooltip>
            </IconButton>
          ) : null}
        </Stack>
      </Stack>
    </div>
  )
}

export const actionsFormatter = (...props) => {
  return <ActionsFormatter row={props[1]} formatExtraData={props[3]} />
}
