import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { find } from 'lodash'
import I18n from 'i18next'
import { decamelize } from 'humps'

import { useGetIndustries } from '@api'
import CommonTable from '@common/components/table'
import { confirm } from '@common/components/alert'
import { HistoryMessagesData, Message } from '@types'
import buildTableColumns from '@pages/history/utils/buildTableColumns'
import { useDeleteMessage, useGetRecipients } from '@pages/history/api'

import ShowRecipientsModal from './show_recipients_modal'
import EditValidityDateModal from './edit_validity_date_modal'

type HistoryTableProps = {
  data: HistoryMessagesData
  error: Error | null
  loading: boolean
  handlePageChanged: (page: number) => Promise<void>
  handleSortChanged: (sortStr: string) => Promise<void>
}

type HistoryTableState = {
  editValidityDateModal: null | number
  expanded: object
  showRecipientsModal: boolean
  coreMessageId: null | number
  editValidityDateModalMsg: null | Message
}

const Table = (props: HistoryTableProps) => {
  const {
    handleSortChanged,
    handlePageChanged,
    loading,
    error,
    data: { messages, page, totalResults },
  } = props
  const [state, setState] = useState<HistoryTableState>({
    editValidityDateModal: null,
    expanded: {},
    showRecipientsModal: false,
    coreMessageId: null,
    editValidityDateModalMsg: null,
  })

  const { mutate: deleteNews } = useDeleteMessage()
  const history = useHistory()
  const { industryMap } = useGetIndustries()
  const { mutate: getMessageRecipients } = useGetRecipients()

  useEffect(() => {
    setState({
      ...state,
      expanded: {},
    })
  }, [page])

  const handleRowExpanded = (id) => {
    setState({
      ...state,
      expanded: {
        ...state.expanded,
        [id]: !state.expanded[id],
      },
    })
  }

  const handleEditValidityDateModalToggle = (id: null | number = null) => {
    const message: Message = messages.find((msg) => msg.id === id) as Message
    setState({ ...state, editValidityDateModal: id, editValidityDateModalMsg: message })
  }

  const handleShowRecipientsModalToggle = (coreMessageId?: number, messageId?: number) => {
    if (coreMessageId && messageId) {
      getMessageRecipients(messageId)
      setState({ ...state, showRecipientsModal: true, coreMessageId })
    } else {
      return setState({ ...state, showRecipientsModal: false, coreMessageId: null })
    }
  }

  const recipientsList = () => {
    return (find(messages, { coreMessageId: state.coreMessageId }) as Message | undefined)?.recipients
  }

  const showNewsDeletionConfirmationModal = (newsId) => {
    confirm({
      title: I18n.t('history.table.news.delete.title'),
      body: I18n.t('history.table.news.delete.body'),
      isDeleteDialog: true,
      proceedLabel: I18n.t('common.delete'),
      isFullWidth: false,
    }).then(
      () => {
        deleteNews(newsId)
      },
      () => null
    )
  }

  const sortChanged = useCallback(
    (sortField, sortOrder) => {
      const sortParameter = `${sortOrder === 'desc' ? '-' : ''}${decamelize(sortField)}`
      handleSortChanged(sortParameter)
    },
    [handleSortChanged]
  )

  const columns = buildTableColumns({
    rowEvents: {
      openEditValidityDateModal: handleEditValidityDateModalToggle,
      openShowRecipientsModal: handleShowRecipientsModalToggle,
      showNewsDeletionConfirmationModal: showNewsDeletionConfirmationModal,
    },
    handleRowExpanded: handleRowExpanded,
    expanded: state.expanded,
    history,
    industryMap,
  })

  return (
    <div data-testId="history-table">
      <CommonTable
        data={messages ?? []}
        loading={loading}
        columns={columns}
        pageChanged={handlePageChanged}
        sortChanged={sortChanged}
        emptyLabel={I18n.t('history.table.messages.empty')}
        perPage={50}
        totalSize={totalResults}
        page={page}
        errors={error ? [error] : []}
      />
      <EditValidityDateModal
        id={state.editValidityDateModal}
        isOpen={!!state.editValidityDateModal}
        onClose={handleEditValidityDateModalToggle}
        message={state.editValidityDateModalMsg}
      />
      <ShowRecipientsModal
        recipients={recipientsList()}
        isOpen={state.showRecipientsModal}
        onClose={handleShowRecipientsModalToggle}
      />
    </div>
  )
}

export default Table
