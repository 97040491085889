import { useMemo } from 'react'
import PropTypes from 'prop-types'
import Creatable from 'react-select/creatable'
import { components } from 'react-select'
import { uniqBy, map } from 'lodash'

import { useTheme } from '@common/theme'

const Tags = (props) => {
  const { theme, themeMode } = useTheme()
  const style = useMemo(() => {
    const { width, height, styles, maxHeight } = props
    const phraseSearchBgColor = themeMode === 'light' ? '#EDF5FF' : theme.palette.info.light
    const wordsSearchBgColor = themeMode === 'light' ? '#ffcc80' : theme.palette.warning.light
    const phraseSearchColor = themeMode === 'light' ? '#004ab3' : theme.palette.info.contrastText
    const wordsSearchColor = themeMode === 'light' ? '#663d00' : theme.palette.warning.contrastText
    return {
      container: (provided) => ({
        ...provided,
        minHeight: height,
        maxHeight,
        overflow: maxHeight ? 'auto' : undefined,
      }),
      control: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: theme.palette.background.default,
        borderColor: state.isFocused ? theme.palette.primary.light : theme.palette.divider,
        width,
        minHeight: height,
        ':hover': {
          ...provided[':hover'],
          borderColor: state.isFocused ? theme.palette.primary.light : theme.palette.text.primary,
        },
      }),
      input: (provided) => ({
        ...provided,
        color: theme.palette.text.primary,
        height: height - 8, // 2 x 2 padding + 2 x 2 margin
        maxWidth: width - 27,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: props.disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
      }),
      menu: () => ({ display: 'none' }),
      // EDF5FF
      multiValue: (provied, state) => ({
        ...provied,
        backgroundColor: state.data.phraseSearch ? phraseSearchBgColor : wordsSearchBgColor,
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: state.data.phraseSearch ? phraseSearchColor : wordsSearchColor,
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: state.data.phraseSearch ? phraseSearchColor : wordsSearchColor,
      }),
      ...styles,
    }
  }, [props, theme, themeMode])

  const { simple, value, disabled } = props
  const selectedValue = useMemo(() => {
    const { simple, value } = props
    return simple ? map(value, (value) => ({ label: value, value })) : value
  }, [simple, value])

  const testId = props['data-testid']
  const Input = useMemo(() => {
    return (inputProps) => components.Input({ ...inputProps, 'data-testid': testId })
  }, [testId])

  const onChange = (keywords) => {
    const { trim, simple } = props
    const values = map(keywords, (keyword) => ({ ...keyword, value: trim ? keyword.value.trim() : keyword.value }))
    props.onChange(simple ? map(values, ({ value }) => value) : values)
  }

  const onBlur = ({ target: { value: inputValue } }) => {
    const { addOnBlur, labelKey } = props
    if (addOnBlur && inputValue) {
      onChange(uniqBy(selectedValue.concat([{ value: inputValue, [labelKey]: inputValue }]), 'value'))
    }
  }

  const onInputKeyDown = (event) => {
    const {
      keyCode,
      target: { value },
    } = event
    if (keyCode === 13 && value === '') {
      props.onEnterPressed(event)
    }
  }

  return (
    <Creatable
      {...props}
      components={{
        Input,
        DropdownIndicator: null,
        ...props.components,
      }}
      value={selectedValue}
      onChange={onChange}
      onKeyDown={onInputKeyDown}
      isMulti
      onBlur={onBlur}
      styles={style}
      isDisabled={disabled}
    />
  )
}

Tags.propTypes = {
  disabled: PropTypes.boolean,
  isClearable: PropTypes.boolean,
  onChange: PropTypes.func.isRequired,
  onEnterPressed: PropTypes.func,
  value: PropTypes.array,
  addOnBlur: PropTypes.bool,
  labelKey: PropTypes.string,
  trim: PropTypes.bool,
  clearable: PropTypes.bool,
  'data-testid': PropTypes.string,
  simple: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  styles: PropTypes.object,
  components: PropTypes.object,
}

Tags.defaultProps = {
  value: [],
  addOnBlur: true,
  labelKey: 'label',
  trim: false,
  simple: false,
  onEnterPressed: () => null,
}

export default Tags
