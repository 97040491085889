import { Box } from '@mui/material'
import { Field, Toggle } from '@riskmethods/rm-front-end'

import { ImTypeEnum } from '@enums'

type Props = {
  type: ImTypeEnum | null
  disabled?: boolean
}

export const OnlyNewsToggle = ({ type, disabled = false }: Props) => {
  const isDisabled = disabled || type === ImTypeEnum.LOCATION

  return (
    <Box width="110px">
      <Field
        name="onlyNews"
        disabled={isDisabled}
        Component={(props) => <Toggle testId="only-news-field" label="News only" {...props} />}
      />
    </Box>
  )
}
