import { Box } from '@mui/material'
import { Field, Toggle } from '@riskmethods/rm-front-end'

import { ImTypeEnum } from '@enums'

type Props = {
  onlyNews: boolean
  type: ImTypeEnum | null
  disabled?: boolean
}

export const NoValidityToggle = ({ onlyNews, type, disabled = false }: Props) => {
  const isDisabled = disabled || onlyNews || type === ImTypeEnum.LOCATION

  return (
    <Box width="140px">
      <Field
        name="noValidity"
        disabled={isDisabled}
        Component={(props) => <Toggle testId="no-validity-date-field" label="No validity date" {...props} />}
      />
    </Box>
  )
}
