import { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Autocomplete, Stack, Chip, Typography, MenuItem, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import { useGetIndicators, useGetCountries, useIndicatorsRelatedDataMaps } from '@api'
import { FiltersCont } from '@common/layout'
import { updateCountryData } from '@pages/im/actions/country'
import { validateData } from '@pages/im/actions/common'

import { FORM_MODE_EDIT } from '@src/client/pages/im/constants/form-mode/form-mode'

import { Buttons, SubtypeSelect } from '@pages/im/components/im-type-specific-form/components/shared'
import { ImFormModeEnum, ImTypeEnum } from '@enums'
import { useIMContext } from '@src/client/hooks'
const OTHER_INDICAROR_RM_CODE = 'RM0166'
const CountryIMForm = (props) => {
  const { refetch: fetchIndicators, data: indicators } = useGetIndicators(ImTypeEnum.COUNTRY)
  const { refetch: fetchCountries, data: countries } = useGetCountries()
  const { updateIMFormValues, imFormValues } = useIMContext()
  const {
    data: { indicatorsDataMap },
  } = useIndicatorsRelatedDataMaps()
  const onlyNews = imFormValues?.onlyNews ?? false

  useEffect(() => {
    fetchIndicators()
    fetchCountries()
  }, [])

  const indicatorsNormalised = useMemo(() => {
    const indicatorsNormalised = indicators ?? []
    if (!onlyNews) {
      return indicatorsNormalised.filter((indicator) => indicator.rmCode !== OTHER_INDICAROR_RM_CODE)
    }
    return indicatorsNormalised
  }, [indicators, onlyNews])

  const configCreate = () => {
    return {
      labels: {
        title: I18n.t(`indicator_message.headers.country_${onlyNews ? 'news' : 'im'}`),
        notice: I18n.t(`common.messages.unable_to_create_${onlyNews ? 'news' : 'im'}`),
        submitButton: I18n.t(`indicator_message.buttons.create_${onlyNews ? 'news' : 'im'}`),
      },
    }
  }

  const configEdit = () => {
    return {
      labels: {
        title: I18n.t('indicator_message.headers.country_edit_news'),
        notice: I18n.t('common.messages.unable_to_update_news'),
        submitButton: I18n.t('indicator_message.buttons.update_news'),
      },
    }
  }

  const configReview = () => {
    return {
      labels: {
        title: I18n.t(`indicator_message.headers.country_edit_${onlyNews ? 'news' : 'im'}`),
        notice: I18n.t(`common.messages.unable_to_update${onlyNews ? '_news' : ''}`),
        submitButton: I18n.t(`indicator_message.buttons.update_${onlyNews ? 'news' : 'im_news'}`),
      },
    }
  }

  const configCopy = () => {
    return {
      labels: {
        title: I18n.t(`indicator_message.headers.country_copy_${onlyNews ? 'news' : 'im'}`),
        notice: I18n.t(`common.messages.unable_to_create_${onlyNews ? 'news' : 'im'}`),
        submitButton: I18n.t(`indicator_message.buttons.create_${onlyNews ? 'news' : 'im'}`),
      },
    }
  }

  const getConfig = () => {
    switch (props.formMode) {
      case ImFormModeEnum.FORM_MODE_CREATE:
        return configCreate()
      case ImFormModeEnum.FORM_MODE_EDIT:
        return configEdit()
      case ImFormModeEnum.FORM_MODE_REVIEW:
        return configReview()
      case ImFormModeEnum.FORM_MODE_COPY:
        return configCopy()
      default:
        return null
    }
  }

  const isEditing = () => props.formMode === FORM_MODE_EDIT

  const handleChange = (field) => (items) => {
    props.updateCountryData({ [field]: items })
  }

  const handleIndicatorChange = (event) => {
    const { value } = event.target
    const selectedIndicator = indicatorsDataMap?.get(value) ?? null
    updateIMFormValues({ indicatorSubtypes: [], selectedIndicator })
  }

  const handleIndicatorMessageChange = (event) => {
    const { value } = event.target
    const selectedCountry = countries?.find((country) => country.code === value)

    props.updateCountryData({ indicatorMessageCountry: selectedCountry })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const { validateData, saveMessage } = props
    const { valid } = validateData('COUNTRY', !isEditing(), imFormValues)

    if (valid) {
      saveMessage()
    }
  }

  const showNotice = () => {
    return !isEmpty(props.errors) || props.hasErrors
  }

  const disableSubmit = () => {
    const { inProgress } = props
    return inProgress
  }

  const handleSubtypeChange = useCallback(
    (value) => {
      updateIMFormValues({ indicatorSubtypes: value })
    },
    [updateIMFormValues]
  )

  const { data, errors, formMode } = props
  const config = getConfig()
  const selectedIndicator = imFormValues?.selectedIndicator
  const isOnHistoryPage = formMode === ImFormModeEnum.FORM_MODE_EDIT
  return (
    <Stack sx={{ pt: 2, px: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {config?.labels.title}
        {showNotice() && (
          <Chip color="error" label={config?.labels.notice} sx={{ borderRadius: '4px', ml: 2, fontSize: '1.1rem' }} />
        )}
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <FiltersCont sx={{ mb: 2 }}>
          <TextField
            select
            required
            value={selectedIndicator?.rmCode ?? ''}
            label={I18n.t('indicator_message.form.general.labels.indicator')}
            onChange={(ev) => handleIndicatorChange(ev)}
            sx={{ minWidth: '20rem' }}
            error={!!errors.indicator}
            helperText={errors.indicator}
            disabled={isOnHistoryPage}
            data-testid="indicator_select"
          >
            {(indicatorsNormalised || []).map((option) => (
              <MenuItem key={option.rmCode} value={option.rmCode} data-testid={`indicator_${option.rmCode}`}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <SubtypeSelect
            subtypes={selectedIndicator?.subtypes ?? []}
            value={imFormValues?.indicatorSubtypes ?? null}
            onChange={handleSubtypeChange}
            error={errors?.indicatorSubtypes}
            disabled={!selectedIndicator?.subtypes?.length}
            sx={{ minWidth: '20rem' }}
          />
          {!onlyNews && (
            <TextField
              select
              required
              value={countries ? (data.indicatorMessageCountry?.code ?? '') : ''}
              label={I18n.t('indicator_message.form.general.labels.indicator_message')}
              onChange={(ev) => handleIndicatorMessageChange(ev)}
              sx={{ minWidth: '20rem' }}
              error={!!errors.indicatorMessage}
              helperText={errors.indicatorMessage}
              data-testid="indicator_message_country_select"
              disabled={isOnHistoryPage}
            >
              {(countries || []).map((option) => (
                <MenuItem key={option.id} value={option.code} data-testid={`indicator_message_${option.code}`}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Autocomplete
            multiple
            options={countries || []}
            getOptionLabel={({ name }) => name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={countries ? data.newsCountries : []}
            onChange={(ev, values) => handleChange('newsCountries')(values)}
            data-testid={'news_country_select'}
            disabled={isOnHistoryPage}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  sx={{ '&.MuiChip-sizeSmall': { my: '1px' } }}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                disabled={isOnHistoryPage}
                label={I18n.t('indicator_message.form.general.labels.news')}
                sx={{ minWidth: '30rem', maxWidth: '45rem' }}
                error={!!errors.news}
                helperText={errors.news}
              />
            )}
          />
        </FiltersCont>
        <Buttons
          submit={{ disabled: disableSubmit(), label: config?.labels.submitButton || '' }}
          back={{ onClick: props.onBack }}
          cancel={{ onClick: props.onCancel }}
        />
      </form>
    </Stack>
  )
}

const mapStateToProps = (rootState) => {
  const {
    formMode,
    country: { data, errors },
    save,
  } = rootState.indicatorMessage

  return {
    formMode,
    data,
    errors,
    inProgress: save.inProgress,
    hasErrors: save.hasErrors,
  }
}

const mapDispatchToProps = { updateCountryData, validateData }

CountryIMForm.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  updateCountryData: PropTypes.func,
  onlyNews: PropTypes.bool,
  inProgress: PropTypes.bool,
  hasErrors: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveMessage: PropTypes.func.isRequired,
  validateData: PropTypes.func.isRequired,
  formMode: PropTypes.string,
  // react-router props
  history: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryIMForm)
