import { Indicator, IndicatorsData, IndicatorSubtype } from '@types'
import { QueryFunction, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetch } from '@services'
import { camelizeKeys } from 'humps'
import { useGetIndicators } from '@api'

type IndicatorsDataRelatedMaps = {
  indicatorsSubtypesMap: Map<string, IndicatorSubtype> | null
  indicatorsDataMap: Map<string, Indicator> | null
}

const convertAllIndicatorsDataToIndicatorsSubtypesMap = (indicators: IndicatorsData): IndicatorsDataRelatedMaps => {
  const indicatorsSubtypesMap = new Map()
  const indicatorsDataMap = new Map()
  indicators.forEach((indicatorData) => {
    indicatorsDataMap.set(indicatorData.rmCode, indicatorData)
    indicatorData?.subtypes?.forEach((indicatorSubtypesData: IndicatorSubtype) => {
      indicatorsSubtypesMap.set(indicatorSubtypesData.code, indicatorSubtypesData)
    })
  })

  return { indicatorsSubtypesMap, indicatorsDataMap }
}
const QUERY_KEY = 'USE_GET_INDICATORS_RELATED_MAP'
const defaultIndicatorsDataRelatedMaps: IndicatorsDataRelatedMaps = {
  indicatorsSubtypesMap: null,
  indicatorsDataMap: null,
}
const isCachedIndicatorSubtypesMapEmpty = (indicatorsDataRelatedMaps: IndicatorsDataRelatedMaps) => {
  return (
    indicatorsDataRelatedMaps.indicatorsDataMap === null && indicatorsDataRelatedMaps.indicatorsSubtypesMap === null
  )
}

const useIndicatorsRelatedDataMaps = () => {
  const { data, isSuccess } = useGetIndicators()
  const queryClient = useQueryClient()
  const cachedIndicatorSubtypesMap = queryClient.getQueryData<IndicatorsDataRelatedMaps>([QUERY_KEY])
  const fetchIndicatorsDataRelatedMaps: QueryFunction<
    IndicatorsDataRelatedMaps,
    QueryKey
  > = async (): Promise<IndicatorsDataRelatedMaps> => {
    if (!cachedIndicatorSubtypesMap || isCachedIndicatorSubtypesMapEmpty(cachedIndicatorSubtypesMap)) {
      let indicators: IndicatorsData | null = null
      if (isSuccess && data) {
        indicators = data
      } else {
        const res = await fetch('/api/indicators')
        if (res.ok) {
          const data: unknown = await res.json()
          if (data && typeof data === 'object') {
            const parsedData = camelizeKeys(data) as { indicators: IndicatorsData | null }
            indicators = parsedData.indicators
          }
        }
      }
      const indicatorsSubtypesMap = indicators
        ? convertAllIndicatorsDataToIndicatorsSubtypesMap(indicators)
        : { ...defaultIndicatorsDataRelatedMaps }
      return indicatorsSubtypesMap
    }
    return cachedIndicatorSubtypesMap
  }
  return useQuery<NonNullable<IndicatorsDataRelatedMaps>>({
    queryKey: [QUERY_KEY],
    queryFn: fetchIndicatorsDataRelatedMaps,
    enabled: isSuccess || !cachedIndicatorSubtypesMap,
    initialData: { ...defaultIndicatorsDataRelatedMaps },
  })
}

export default useIndicatorsRelatedDataMaps
