// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-text--FAh9F{position:absolute;font-family:"Open Sans",sans-serif;font-weight:600;color:#fff}`, "",{"version":3,"sources":["webpack://./src/client/pages/world-map/components/loading-text/styles.scss"],"names":[],"mappings":"AAAA,qBACE,iBAAA,CACA,kCAAA,CACA,eAAA,CACA,UAAA","sourcesContent":[".loading-text {\n  position: absolute;\n  font-family: 'Open Sans', sans-serif;\n  font-weight: 600;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `loading-text--FAh9F`;
export { _1 as "loading-text" };
export var loadingText = `loading-text--FAh9F`;
export default ___CSS_LOADER_EXPORT___;
