import I18n from 'i18next'

import { getJSON } from '@common/http'
import { validatorSupplierSearchForm } from '@pages/im/validators/supplier_search_form'

export const SUPPLIERS_FETCHED = 'SUPPLIERS_FETCHED'
export const SUPPLIERS_LOADING = 'SUPPLIERS_LOADING'
export const CLEAR_FORM = 'CLEAR_FORM'
export const REMOVE_FORM = 'REMOVE_FORM'
export const REMOVE_SUPPLIER = 'REMOVE_SUPPLIER'
export const FORM_VALID = 'SUPPLIER_FORM_VALID'
export const FORM_INVALID = 'SUPPLIER_FORM_INVALID'
export const FORM_CHANGED = 'SUPPLIER_FORM_CHANGED'
export const REMOVE_SEARCH = 'REMOVE_SEARCH'

const specialCharactersRegex = /[!"#$%&'*+,-./:;—<=>?@[\]^_{|}~\\`]/g

const serializeName = (name) => name.toLowerCase().replace(specialCharactersRegex, ' ')

const parseNameToWords = (name) => name.split(' ').filter((word) => Boolean(word))

const isArrayOrderedSubset = (firstArray, secondArray) => {
  if (secondArray.length > firstArray.length) {
    return false
  }

  for (let i = 0; i <= firstArray.length - secondArray.length; i++) {
    for (let j = 0; j < secondArray.length; j++) {
      if (firstArray[i + j] !== secondArray[j]) {
        break
      }
      if (j === secondArray.length - 1) {
        return true
      }
    }
  }

  return false
}

/**
 * Check if serialized name split into list of words contains...
 * an ordered subset of the serialized exclude name words.
 * We do not want to simply check if the name contains the excluded name...
 * as 'supplier name' contains 'er na', but we do not treat it as a match
 * Name should contain exact words specified in the exclude name
 */
const isMatchingExactName = (supplierNameWords, excludeName) => {
  const formattedExcludeName = serializeName(excludeName)
  const excludeNameWords = parseNameToWords(formattedExcludeName)

  if (!excludeNameWords.length) {
    return true
  }

  return isArrayOrderedSubset(supplierNameWords, excludeNameWords)
}

/**
 * Check if serialized name split into list of words exactly matches
 * with all of the exclude name serialized parts split into words
 */
const isMatchingName = (supplierNameWords, excludeName) => {
  const excludeNameParts = parseNameToWords(excludeName)
  if (!excludeNameParts.length) {
    return false
  }

  const arePartsMatching = excludeNameParts.every((excludeNamePart) =>
    isMatchingExactName(supplierNameWords, excludeNamePart)
  )

  return arePartsMatching
}

const getShouldExcludeSupplier = (supplierNameWords, excludeNames) =>
  excludeNames.some(({ name: excludeName, phraseSearch }) => {
    if (!excludeName) {
      return false
    }

    const isMatchingCallback = phraseSearch ? isMatchingExactName : isMatchingName

    return isMatchingCallback(supplierNameWords, excludeName)
  })

export const filterExcludedSuppliers = (suppliers = [], excludeNames = []) => {
  if (!excludeNames.length) {
    return suppliers
  }

  const filteredSuppliers = suppliers.filter((supplier) => {
    const { name: supplierName } = supplier
    if (!supplierName) {
      return true
    }
    // we have to ingore substrings wrapped with parentheses as they are ignored in excludeNames
    const cleanSupplierName = supplierName.replace(/\([^()]*\)/gi, '').replace(/\s+/g, ' ') // name (test)    supplier => name supplier

    const formattedSupplierName = serializeName(cleanSupplierName)
    const supplierNameWords = parseNameToWords(formattedSupplierName)

    const shouldExcludeSupplier = getShouldExcludeSupplier(supplierNameWords, excludeNames)

    return !shouldExcludeSupplier
  })

  return filteredSuppliers
}

export const fetchSuppliers = (filters, type, searchName) => (dispatch) => {
  dispatch({ type: SUPPLIERS_LOADING, metaData: { type } })
  const { valid, errors } = validatorSupplierSearchForm(filters)
  if (!valid) {
    return dispatch({
      type: FORM_INVALID,
      data: { ...errors },
    })
  }
  dispatch({ type: FORM_VALID })

  // do not pass excludeNames as we are filtering suppliers on the client side
  const queryParams = { ...filters, excludeNames: undefined }

  return getJSON('/api/clusters', queryParams)
    .then(({ data: { clusters } }) => filterExcludedSuppliers(clusters || [], filters?.excludeNames))
    .then((filteredBusinessPartners) => {
      dispatch({
        type: SUPPLIERS_FETCHED,
        data: filteredBusinessPartners,
        metaData: { type, searchName },
      })
    })
    .catch(({ status, data: { error, errors } }) => {
      let msg = error || errors
      if (!msg) {
        if ([503, 504].includes(status)) {
          msg = I18n.t('common.req_err_msgs.request_timeout')
        } else if (status >= 500) {
          msg = I18n.t('common.req_err_msgs.internal_server_error')
        } else {
          msg = I18n.t('common.req_err_msgs.something_went_wrong')
        }
      }
      dispatch({
        type: FORM_INVALID,
        data: { searchRequestTimeout: msg },
      })
    })
}

export const removeSearch = (id, type) => ({
  type: REMOVE_SEARCH,
  data: id,
  metaData: { type },
})

export const clearForm = (type) => ({
  type: CLEAR_FORM,
  metaData: { type },
})

// I introduced this action to fix the bug with deleted targets
// appear back when `only news` is selected. I didn't want to
// apply the fix for clearForm action/reducer, as there is possibility,
// that what is bug here, is actually a feature in other place ¯\_(ツ)_/¯
export const removeForm = (type) => ({
  type: REMOVE_FORM,
  metaData: { type },
})

export const formChanged = (type) => ({
  type: FORM_CHANGED,
  metaData: { type },
})

export const removeSupplier = (businessPartner, type) => ({
  type: REMOVE_SUPPLIER,
  metaData: { type },
  data: businessPartner,
})
