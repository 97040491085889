import { pick, isNumber } from 'lodash'

import { validationCountry } from '@pages/im/validators/country'
import { validationSupplier } from '@pages/im/validators/supplier'
import { validationLocation } from '@pages/im/validators/location'

const alwaysValid = () => ({ valid: true })

const validationFunctionMap = {
  country: {
    create: validationCountry,
    edit: alwaysValid,
  },
  supplier: {
    create: validationSupplier,
    edit: alwaysValid,
  },
  location: {
    create: validationLocation,
  },
}

const formInvalid = (type, errors) => ({
  type: `${type}_FORM_INVALID`,
  data: errors,
})

const formValid = (type) => ({
  type: `${type}_FORM_VALID`,
})

export const transformLocations = (state, requiredFields) => {
  const {
    location: {
      data: { selectedLocations, radius, polygonPoints },
    },
  } = state
  return {
    ...transformPolygonLocations(selectedLocations, polygonPoints, requiredFields.polygons),
    ...transformRadiusLocations(selectedLocations, parseFloat(radius), requiredFields.areas),
  }
}

const transformPolygonLocations = (selectedLocations, polygonPoints, requiredFields) => {
  if (!polygonPoints) {
    return {}
  }

  return {
    polygons: selectedLocations.map((location) => ({
      ...pick(location, requiredFields),
      coordinates: polygonPoints,
    })),
  }
}

const transformRadiusLocations = (selectedLocations, radius, requiredFields) =>
  isRadiusValid(radius)
    ? { areas: selectedLocations.map((location) => ({ ...pick(location, requiredFields), radius })) }
    : {}

export const isRadiusValid = (radius) => {
  return isNumber(radius) && radius >= 0
}

export const validateData = (type, isCreating = true, imFormValues) => {
  return (dispatch, getState) => {
    const state = getState().indicatorMessage
    const normalizedType = type.toLowerCase()
    const validationFunction = validationFunctionMap[normalizedType][isCreating ? 'create' : 'edit']
    const { data } = state[normalizedType]
    const { valid, errors } = validationFunction(data, imFormValues)
    if (valid) {
      dispatch(formValid(type))
    } else {
      dispatch(formInvalid(type, errors))
    }
    return { valid, errors }
  }
}
