import { Component } from 'react'
import { connect } from 'react-redux'
import { uniqueId, debounce } from 'lodash'
import PropTypes from 'prop-types'

import { CustomControl } from '@riskmethods/world-map'
import { location as locationShape, indicator as indicatorShape, position as positionShape } from '@common/utils/shapes'
import LoadingBar from '@pages/world-map/components/loading-bar/index'
import LayerControl from '@pages/world-map/components/layer-control/index'
import { LoadButton } from '@pages/world-map/components/load-button'
import { init, boundsChanged, requestData } from '@pages/world-map/actions'

import {
  refreshMap,
  mapUpdated,
  mapBoundsFitted,
  updateLocationDataAndRefresh,
  appendLocation,
} from '@pages/im/actions/location'

import * as styles from '../../location-im-form.scss'

import WorldMap from '@common/components/map'

class Map extends Component {
  state = {
    locationFeatures: [],
    innerUpdateMap: false,
  }
  static propTypes = {
    radius: PropTypes.number,
    selectedLocations: PropTypes.arrayOf(locationShape).isRequired,
    updateMap: PropTypes.bool,
    fitMapBounds: PropTypes.bool,
    // editable is taken from the app redux state,
    editable: PropTypes.bool,
    // disabled is supposed to be passed when the component is used
    disabled: PropTypes.bool,
    draggable: PropTypes.bool,
    mapUpdated: PropTypes.func.isRequired,
    mapBoundsFitted: PropTypes.func.isRequired,
    indicator: indicatorShape,
    findLocationLoading: PropTypes.bool.isRequired,
    updateLocationDataAndRefresh: PropTypes.func.isRequired,
    appendLocation: PropTypes.func.isRequired,
    isPolygon: PropTypes.bool.isRequired,
    polygonPoints: PropTypes.arrayOf(positionShape),
    layerFilter: PropTypes.object.isRequired,
    bounds: PropTypes.object,
    layersControlUnit: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
    boundsChanged: PropTypes.func.isRequired,
    layers: PropTypes.object.isRequired,
    requestData: PropTypes.func.isRequired,
    init: PropTypes.func.isRequired,
    onPolygonChange: PropTypes.func,
    onPolygonComplete: PropTypes.func.isRequired,
    disableMapTopRightControls: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.shouldUpdateMapRef = true
    this.debouncedSetLocationFeatures = debounce(() => {
      this.setLocationFeatures()
    }, 500)
  }

  componentDidMount() {
    const { layerFilter, bounds } = this.props
    this.props.init(layerFilter, bounds)
    this.setLocationFeatures()
  }

  componentDidUpdate(oldProps) {
    if (oldProps === this.props) {
      return
    }

    if (this.props.updateMap) {
      this.shouldUpdateMapRef = true
    }

    this.debouncedSetLocationFeatures()
  }

  geometry = ({ latitude, longitude, polygonPoints }) => {
    if (polygonPoints) {
      return {
        type: 'GeometryCollection',
        geometries: [
          {
            type: 'Polygon',
            coordinates: [polygonPoints.map(({ latitude, longitude }) => [longitude, latitude])],
          },
        ],
      }
    } else {
      return {
        type: 'Point',
        coordinates: [longitude, latitude],
      }
    }
  }

  setLocationFeatures = () => {
    const { radius, selectedLocations, indicator, polygonPoints, editable, draggable } = this.props
    const locationFeatures = selectedLocations.map(({ latitude, longitude, name }) => ({
      id: uniqueId('location-'),
      type: 'Feature',
      geometry: this.geometry({ latitude, longitude, polygonPoints }),
      properties: {
        name,
        radius,
        editable: !!editable,
        draggable,
        type: 'Event',
        eventType: indicator && indicator.eventType,
        noTooltip: true,
        showRadius: true,
        icon: {
          scaledSize: [34, 30],
          anchor: [17, 15],
          blue: true,
        },
      },
    }))
    this.setState({ locationFeatures: [...locationFeatures], innerUpdateMap: this.shouldUpdateMapRef })
    this.shouldUpdateMapRef = false
  }

  onMapUpdate = () => {
    this.setState({ innerUpdateMap: false })
    this.props.mapUpdated()
  }

  drawingIsEnabled = () => {
    return this.props.isPolygon && !this.props.polygonPoints
  }

  onMapClick = ({ latLng: { lat, lng } }) => {
    const { findLocationLoading, appendLocation, polygonPoints } = this.props
    if (!findLocationLoading && !this.drawingIsEnabled() && !polygonPoints && !this.props.disabled) {
      appendLocation(lat, lng)
    }
  }

  render() {
    const { innerUpdateMap, locationFeatures } = this.state
    const {
      updateMap,
      fitMapBounds,
      mapBoundsFitted,
      findLocationLoading,
      layersControlUnit,
      layerFilter,
      loading,
      features,
      boundsChanged,
      disabled,
    } = this.props
    const allFeatures = features.concat(locationFeatures)

    return (
      <div className={styles.map} style={{ opacity: findLocationLoading ? 0.6 : 1 }}>
        <LoadingBar loading={loading} />
        <WorldMap
          features={allFeatures}
          loading={loading.isLoading}
          updateMap={updateMap || innerUpdateMap}
          fitBounds={fitMapBounds}
          fitBoundsMode="ALL"
          onUpdateData={this.onMapUpdate}
          onFittedBounds={mapBoundsFitted}
          clustering="ALL"
          enableSpiderfier={false}
          enableClusteringColors={false}
          onMapClick={this.onMapClick}
          enableDrawing={this.drawingIsEnabled()}
          onPolygonComplete={this.props.onPolygonComplete}
          onPolygonChange={this.props.onPolygonChange}
          onBoundsChanged={boundsChanged}
          triggerMapUpdate={this.props.refreshMap}
          disableClicks={disabled}
        >
          <CustomControl position="TOP_RIGHT">
            <LayerControl
              isLoading={loading.isLoading}
              layers={layerFilter}
              layersControlUnit={layersControlUnit}
              disabled={this.props.disableMapTopRightControls}
            />
          </CustomControl>
          <CustomControl position="TOP_RIGHT">
            <LoadButton reload={this.reload} disabled={this.props.disableMapTopRightControls} />
          </CustomControl>
        </WorldMap>
      </div>
    )
  }

  reload = () => {
    const { layerFilter, layers, bounds } = this.props
    this.props.requestData(layerFilter, layers, bounds)
  }
}

const mapStateToProps = (rootState) => {
  const {
    location: {
      data: {
        radius,
        selectedLocations,
        indicator,
        isPolygon,
        polygonPoints,
        findLocation: { loading: findLocationLoading },
        editable,
        draggable,
      },
      updateMap,
      fitMapBounds,
      bounds,
      layers,
      features,
      loading,
      filter: { layers: layerFilter },
      visibility: { layersControlUnit },
    },
  } = rootState.indicatorMessage

  return {
    indicator,
    radius,
    selectedLocations,
    updateMap,
    fitMapBounds,
    editable,
    draggable,
    findLocationLoading,
    isPolygon,
    polygonPoints,
    bounds,
    layers,
    layerFilter,
    features,
    loading,
    layersControlUnit,
  }
}

const mapDispatchToProps = {
  refreshMap,
  mapUpdated,
  mapBoundsFitted,
  updateLocationDataAndRefresh,
  appendLocation,
  requestData,
  boundsChanged,
  init,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
