import { ImFormSupplierData, ImSearch, ImTarget } from '@src/client/types'
import { priorityFormTypeEnum } from '@enums'
import { isImBundleTargetLocation, isImTargetLocation } from '@common/utils/type_guards'

export function getAvailableSuppliersFromSearchResults(searches: ImSearch[] = []): ImTarget[] {
  const availableSuppliers: ImTarget[] = []
  searches.forEach((search) => {
    search.result.forEach((searchResult) => {
      availableSuppliers.push(searchResult)
    })
  }, [])

  return availableSuppliers
}

function combineAvailableSuppliers(priorityFormSearchResults: ImSearch[], otherFormSuppliers: ImTarget[]) {
  const priorityFormAvailableSuppliers: ImTarget[] = getAvailableSuppliersFromSearchResults(priorityFormSearchResults)
  const map: Record<string, boolean> = {}
  const uniqCombinedSupliers: ImTarget[] = []
  const combinedAvailableSuppliers = [...priorityFormAvailableSuppliers, ...otherFormSuppliers]

  combinedAvailableSuppliers.forEach((supplier) => {
    const mapKey =
      isImBundleTargetLocation(supplier) || isImTargetLocation(supplier) ? JSON.stringify(supplier) : supplier.id
    if (!map[mapKey]) {
      uniqCombinedSupliers.push(supplier)
      map[mapKey] = true
    }
  })

  return uniqCombinedSupliers
}

export function getAvailableSuppliers(
  messageForms: ImFormSupplierData,
  priorityForm: priorityFormTypeEnum
): [ImTarget[], ImTarget[]] {
  const alertFormAvailableSuppliers =
    priorityForm === priorityFormTypeEnum.ALERT_FORM
      ? getAvailableSuppliersFromSearchResults(messageForms.alertForm.searches)
      : combineAvailableSuppliers(messageForms.newsForm.searches, messageForms.alertForm.suppliers)

  const newsFormAvailableSuppliers =
    priorityForm === priorityFormTypeEnum.NEWS_FORM
      ? getAvailableSuppliersFromSearchResults(messageForms.newsForm.searches)
      : combineAvailableSuppliers(messageForms.alertForm.searches, messageForms.newsForm.suppliers)

  return [alertFormAvailableSuppliers, newsFormAvailableSuppliers]
}
