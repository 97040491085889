import { useEffect, useState, useCallback } from 'react'
import { Button, Stack, Typography, DialogContentText } from '@mui/material'
import Validator from 'validatorjs'
import I18n from 'i18next'
import { useUpdateMessage } from '@pages/history/api'
import Modal from '@common/components/modal'
import DatePicker from '@common/components/date_picker'
import { Message } from '@types'

const validate = (data) => {
  const validator = new Validator(
    data,
    { validUntil: 'date' },
    { date: I18n.t('common.messages.validation.invalid_date') }
  )

  return validator.fails() ? validator.errors.all() : null
}

type EditValidityDateModalProps = {
  id: number | null
  message: Message | null
  isOpen: boolean
  onClose: (id?) => void
}

const EditValidityDateModal = ({ id, message, isOpen, onClose }: EditValidityDateModalProps) => {
  const [formState, setFormState] = useState<{ validUntil?: string | null; errors }>({
    validUntil: message?.validUntil || null,
    errors: {},
  })

  useEffect(() => {
    setFormState({ validUntil: message?.validUntil, errors: {} })
  }, [message?.validUntil])

  const { mutate: updateMessage } = useUpdateMessage()
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleChange = useCallback(
    (validUntil: string) => {
      setFormState({ validUntil, errors: {} })
    },
    [setFormState]
  )

  const handleFormSubmit = useCallback(
    async (ev) => {
      ev.preventDefault()
      if (id) {
        const { validUntil } = formState
        const errors = validUntil ? validate({ validUntil: String(validUntil).split('.').reverse().join('-') }) : null
        if (errors) {
          setFormState({ ...formState, errors })
        } else {
          await updateMessage({ id, message: { ...message, validUntil } })
          onClose()
        }
      }
    },
    [id, formState, updateMessage, onClose, message]
  )

  const Footer = (
    <>
      <Button color="primary" onClick={handleFormSubmit}>
        {I18n.t('common.ok')}
      </Button>
      <Button color="secondary" onClick={handleClose}>
        {I18n.t('common.cancel')}
      </Button>
    </>
  )

  return (
    <Modal
      title={I18n.t('indicator_message.modals.edit_validity_date.title')}
      footer={Footer}
      onClose={handleClose}
      isOpen={isOpen}
      bodyStyle={{ overflow: 'initial' }}
      isEditingDialog={true}
    >
      <DialogContentText>{I18n.t('indicator_message.modals.edit_validity_date.body.line_1')}</DialogContentText>
      <DialogContentText>{I18n.t('indicator_message.modals.edit_validity_date.body.line_2')}</DialogContentText>
      <br />
      <Stack
        component="form"
        direction={'row'}
        alignItems={'center'}
        sx={{ mt: 1 }}
        gap={1}
        onSubmit={handleFormSubmit}
      >
        <Typography variant="subtitle1">{I18n.t('indicator_message.form.general.labels.valid_until')}</Typography>
        <DatePicker
          required
          error={formState.errors?.validUntil}
          value={formState?.validUntil}
          onChange={handleChange}
        />
      </Stack>
    </Modal>
  )
}

export default EditValidityDateModal
