import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Bundle, ReviewBundleData } from '@src/client/types'

const useSetFreezedBundle = () => {
  const queryClient = useQueryClient()

  const mutationFn = async (bundleId: number): Promise<void> => {
    await queryClient.setQueryData(['GET_REVIEW_DATA'], (data: ReviewBundleData): ReviewBundleData => {
      return {
        ...data,
        bundles: data.bundles.map((messageBundleData: Bundle) =>
          messageBundleData.id === bundleId ? { ...messageBundleData, freezed: true } : messageBundleData
        ),
      }
    })
  }

  return useMutation<void, void, number>({
    mutationKey: ['SET_FREEZED_MESSAGE_BUNDLE'],
    mutationFn,
  })
}

export default useSetFreezedBundle
