import { uniq } from 'lodash'
import { Tooltip } from '@mui/material'

import * as styles from './show_more_formatter.scss'

export const showMoreFormatter = (items, rowIndex, formatExtraData, numOfVisibleTargets = 1) => {
  const targets = uniq(items)

  const renderVisibleTargets = targets.slice(0, numOfVisibleTargets).map((name) => (
    <span id={`span_${rowIndex}`} key={name}>
      {name}
      <br />{' '}
    </span>
  ))

  return (
    <div key={rowIndex}>
      {targets.length === 1 || formatExtraData.expanded[rowIndex] ? (
        renderVisibleTargets
      ) : (
        <Tooltip title={targets.join(', ')} placement="top">
          <span>{renderVisibleTargets}</span>
        </Tooltip>
      )}
      {formatExtraData.expanded[rowIndex] &&
        targets.slice(numOfVisibleTargets).map((name) => (
          <span key={name}>
            {name}
            <br />{' '}
          </span>
        ))}
      {targets.length >= numOfVisibleTargets + 1 && (
        <div>
          <button type="button" className={styles.toggle} onClick={() => formatExtraData.handleRowExpanded(rowIndex)}>
            {formatExtraData.expanded[rowIndex] ? 'show less' : 'show more'}
          </button>
        </div>
      )}
    </div>
  )
}
