import { isEmpty } from 'lodash'
import moment from 'moment'

import { IndicatorMessageFormValues, ImBody } from '@types'

import { transformSupplier } from '../transform-supplier'
import { transformLocations } from '../transform-locations'
import { transformCountry } from '../transform-country'
import { ImTypeEnum } from '@enums'

const transformHyperlinks = (hyperlink1: string): string[] => {
  return [hyperlink1].filter((item) => !isEmpty(item))
}

const transformDate = (date: Date): string | null => {
  return date ? moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') : null
}

export const getMsgBundleReqBody = (
  state,
  imFormValues: IndicatorMessageFormValues,
  articleId: number | null = null
): ImBody => {
  const {
    onlyNews,
    type,
    industries,
    subject,
    sourceForContent,
    sourceForCopyright,
    sourceUrl,
    content,
    validUntil,
    noValidity,
    indicatorSubtypes,
    selectedIndicator,
  } = imFormValues

  const requestBody = {
    message: {
      body: content,
      hyperlinks: transformHyperlinks(sourceUrl),
      industries,
      noValidity,
      onlyNews,
      score: 100,
      sourceContent: sourceForContent,
      sourceCopyright: sourceForCopyright,
      subject,
      type,
      validUntil: validUntil ? transformDate(validUntil) : null,
      hitId: articleId || null,
      indicatorSubtypes: indicatorSubtypes ?? [],
      indicatorId: selectedIndicator?.rmCode ?? null,
    },
  } as ImBody

  switch (type) {
    case ImTypeEnum.BUSINESS_PARTNER: {
      const {
        supplier: {
          data: {
            alertForm: { searches: alertSearches },
            newsForm: { searches: newsSearches },
          },
          priorityForm,
        },
      } = state

      requestBody.message.businessPartner = transformSupplier(state, imFormValues.onlyNews)
      requestBody.message.businessPartnerSearches = { indicatorMessageSearches: alertSearches, newsSearches }
      requestBody.message.formPriorityReverse = priorityForm === 'newsForm'

      break
    }

    case ImTypeEnum.LOCATION: {
      requestBody.message.location = transformLocations(state, {
        areas: ['latitude', 'longitude', 'name'],
        polygons: ['name'],
      })

      break
    }

    case ImTypeEnum.COUNTRY: {
      requestBody.message.country = transformCountry(state, imFormValues.onlyNews)

      break
    }
  }

  return requestBody
}
