import { useQuery } from '@tanstack/react-query'
import { camelizeKeys } from 'humps'

import { fetch, displayError } from '@services'
import type { GetImSchema, ImAction, ImData } from '@types'

const transformData = (data: GetImSchema | undefined): ImData | null => {
  if (!data) return null
  return camelizeKeys<GetImSchema>(data) as ImData
}

const queryFn = async (action: string, id: number): Promise<ImData | null> => {
  const res = await fetch(`/api/v2/indicator_messages/${action}?id=${id}`)

  if (res.ok) {
    return transformData(await res.json())
  } else {
    displayError('Could not fetch indicator message this time.')
    return null
  }
}

const useGetIndicatorMessage = (action: ImAction, id: number | null) => {
  if (id === null) {
    return null
  }

  return useQuery<ImData | null>({
    queryKey: ['GET_INDICATOR_MESSAGE', id],
    queryFn: () => queryFn(action, id),
    enabled: false,
    retry: false,
    refetchOnMount: false,
  })
}

export default useGetIndicatorMessage
