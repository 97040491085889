// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-bar--gcaFo{z-index:1100;position:absolute;bottom:20px;right:15%;left:15%}.loading-bar--gcaFo .loading-bar-title--gNLCn{font-size:10px;line-height:16px;font-weight:bold;text-align:right;margin-bottom:-16px;right:25px;display:block;width:99%}`, "",{"version":3,"sources":["webpack://./src/client/pages/world-map/components/loading-bar/styles.scss"],"names":[],"mappings":"AAAA,oBACE,YAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,QAAA,CAEA,8CACE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,gBAAA,CACA,mBAAA,CACA,UAAA,CACA,aAAA,CACA,SAAA","sourcesContent":[".loading-bar {\n  z-index: 1100;\n  position: absolute;\n  bottom: 20px;\n  right: 15%;\n  left: 15%;\n\n  .loading-bar-title {\n    font-size: 10px;\n    line-height: 16px;\n    font-weight: bold;\n    text-align: right;\n    margin-bottom: -16px;\n    right: 25px;\n    display: block;\n    width: 99%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `loading-bar--gcaFo`;
export { _1 as "loading-bar" };
export var loadingBar = `loading-bar--gcaFo`;
var _2 = `loading-bar-title--gNLCn`;
export { _2 as "loading-bar-title" };
export var loadingBarTitle = `loading-bar-title--gNLCn`;
export default ___CSS_LOADER_EXPORT___;
