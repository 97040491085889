import I18n from 'i18next'
import {
  nameFormatter,
  alertTargetNameFormatter,
  newsTargetNameFormatter,
  geometryFormatter,
  actionsFormatter,
} from '@pages/review/components/formatters'
import { readMoreFormatter } from '@src/client/common/table_formatters/read_more_formatter'
import { industriesFormatter } from '@src/client/common/table_formatters/industriesFormatter'
import dateComparator from '@common/utils/date_comparator'
import { handleEmptyFormatter } from '@common/table_formatters/handle_empty_formatter'
import { indicatorSubtypesFormatter } from '@common/table_formatters/indicator_subtypes_formatter'
import { wordsBreakFormatter } from '@common/table_formatters/styles'

const buildTableColumns = (props) => [
  {
    dataField: 'id',
    text: I18n.t('common.messages_table.columns.id'),
  },
  {
    dataField: 'subject',
    text: I18n.t('common.messages_table.columns.subject'),
  },
  {
    dataField: 'sourceContent',
    text: I18n.t('common.messages_table.columns.source'),
    formatter: wordsBreakFormatter,
  },
  {
    dataField: 'body',
    text: I18n.t('common.messages_table.columns.body'),
    formatter: readMoreFormatter('hyperlinks[0]'),
  },
  {
    dataField: 'name',
    text: I18n.t('common.messages_table.columns.name'),
    formatter: nameFormatter,
  },
  {
    dataField: 'indicatorSubtype',
    text: I18n.t('common.messages_table.columns.indicator_subtypes'),
    formatter: indicatorSubtypesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'alertTarget',
    text: I18n.t('common.messages_table.columns.alert_target'),
    formatter: alertTargetNameFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'newsTarget',
    text: I18n.t('common.messages_table.columns.news_target'),
    formatter: newsTargetNameFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },

  {
    dataField: 'targets.geometry',
    text: I18n.t('common.messages_table.columns.area'),
    formatter: geometryFormatter,
  },
  {
    dataField: 'industries',
    text: I18n.t('common.messages_table.columns.industries'),
    formatter: industriesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
      industryMap: props.industryMap,
    },
  },
  {
    dataField: 'createdAt',
    text: I18n.t('common.messages_table.columns.created_on'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'createdBy',
    text: I18n.t('common.messages_table.columns.created_by'),
  },
  {
    dataField: 'validUntil',
    text: I18n.t('common.messages_table.columns.valid_until'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'actions',
    text: I18n.t('common.messages_table.columns.action'),
    formatter: actionsFormatter(props.history),
    formatExtraData: {
      onApprove: props.onApprove,
      onDelete: props.onDelete,
    },
    headerAlign: 'center',
  },
]

export default buildTableColumns
