// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title--Fd8ko{display:flex;align-items:center}button.close--SMHgd{position:absolute;right:.5rem;top:.5rem}.content--wrDqG{overflow:auto;max-height:70vh}`, "",{"version":3,"sources":["webpack://./src/client/common/components/modal.scss"],"names":[],"mappings":"AAAA,cACE,YAAA,CACA,kBAAA,CAGF,oBACE,iBAAA,CACA,WAAA,CACA,SAAA,CAGF,gBACE,aAAA,CACA,eAAA","sourcesContent":[".title {\n  display: flex;\n  align-items: center;\n}\n\nbutton.close {\n  position: absolute;\n  right: 0.5rem;\n  top: 0.5rem;\n}\n\n.content {\n  overflow: auto;\n  max-height: 70vh;\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `title--Fd8ko`;
export var close = `close--SMHgd`;
export var content = `content--wrDqG`;
export default ___CSS_LOADER_EXPORT___;
