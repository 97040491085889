import { isEmpty } from 'lodash'
import I18n from 'i18next'
import { ImFormSupplierData, ImValidationResult } from '@types'
import { imValidationValidResult } from '../constants'

const REQUIRED_FIELDS = ['includeNames', 'cities', 'countries']

const isNotEmpty = (value: unknown): boolean => {
  return value && !isEmpty(value) ? true : false
}

export const validatorSupplierSearchForm = (form: ImFormSupplierData): ImValidationResult => {
  if (REQUIRED_FIELDS.some((key) => isNotEmpty(form[key]))) return imValidationValidResult
  return { valid: false, errors: { searchFormInvalid: I18n.t('common.messages.supplier_search_form_invalid') } }
}
