import { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import qs from 'qs'

import { ROUTES } from '@src/client/common/utils/const'
import { Navbar } from '@components'
import { useGetInitPageData } from '@api'
import { displayError } from '@services'
import { IMContextProvider, SavedHitsContextProvider, useAppContext } from '@hooks'

import {
  InboxPage,
  BusinessPartnersPage,
  WorldMapPage,
  ReviewPage,
  HistoryPage,
  ExclusionListPage,
  ShiftManagementPage,
  IndividualSettingsPage,
  SavedHitsPage,
  IMPage,
  AAReadOnlyPage,
} from '@pages'
import type { ImTypeEnum } from '@enums'
import type { ImAction } from '@types'
import { PAGE_TITLE } from '@src/client/constants'

const RootPage = () => {
  const {
    env: { REMOTE_ENV, VERSION },
  } = useAppContext()

  const getInitialData = useGetInitPageData()

  const {
    refetch: fetchInitialData,
    isFetching: isFetchingInitialData,
    isSuccess: isGetInitialDataSuccess,
    data: initialData,
  } = getInitialData

  const isLocalEnv = !REMOTE_ENV || REMOTE_ENV === 'false'
  const isArticleAnalyzerUser = initialData?.currentUser?.articleAnalyzerUser || false
  const showSavedHitsTab = isLocalEnv || isArticleAnalyzerUser
  const { pathname } = useLocation()

  useEffect(() => {
    if (REMOTE_ENV === 'PRODUCTION') {
      const { version = '' } = JSON.parse(String(localStorage.getItem('ric.app') || '') || '{}')
      if (!version || version !== VERSION) {
        toast.info(`RIC app has been updated. You are using v${VERSION} now.`, {
          autoClose: false,
          position: 'top-right',
          onClose: () => {
            localStorage.setItem('ric.app', JSON.stringify({ version: VERSION }))
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!isGetInitialDataSuccess) {
      fetchInitialData().catch(() => {
        displayError('Could not fetch initial app data.')
      })
    }
  }, [])

  useEffect(() => {
    document.title = PAGE_TITLE[Object.keys(PAGE_TITLE).find((path) => pathname.includes(path)) || '/']
  }, [pathname])

  const ROUTES_ARR = [
    { path: ROUTES.INBOX, labelKey: 'inbox', iconKey: 'inbox' },
    ...(showSavedHitsTab
      ? [
          {
            path: ROUTES.SAVED_HITS,
            labelKey: 'saved_hits',
            iconKey: 'savedHits',
          },
        ]
      : []),
    { path: ROUTES.BUSINESS_PARTNERS, labelKey: 'bp', iconKey: 'bp' },
    { path: ROUTES.WORLD_MAP, labelKey: 'worldmap', iconKey: 'world' },
    { path: ROUTES.REVIEWS, labelKey: 'review', iconKey: 'review' },
    { path: ROUTES.HISTORY, labelKey: 'history', iconKey: 'history' },
    { path: ROUTES.EXCLUSION_LIST, labelKey: 'elist', iconKey: 'elist' },
    { path: ROUTES.SHIFT_MANAGEMENT, labelKey: 'shift_management', iconKey: 'shiftManagement' },
  ]

  if (isFetchingInitialData || !isGetInitialDataSuccess) {
    return null
  }

  return (
    <IMContextProvider>
      <Navbar routesArr={ROUTES_ARR} udmBaseUrl={initialData.udmBaseUrl} />
      <Switch>
        <Route exact path="/inbox">
          <InboxPage initialData={initialData} />
        </Route>
        <Route exact path="/saved_hits">
          <SavedHitsContextProvider>
            <SavedHitsPage initialData={initialData} />
          </SavedHitsContextProvider>
        </Route>
        <Route exact path="/article_history_overview">
          <AAReadOnlyPage />
        </Route>
        <Route
          path="/im/create/:type(business_partner|location|country)?"
          render={(props) => {
            const imType = props.match.params.type as ImTypeEnum
            const articleId = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).article_id)
            if (!imType) {
              return <IMPage action="create" />
            }
            return <IMPage action="create" imType={imType} articleId={articleId} />
          }}
        />
        <Route
          exact
          path="/review/:action(edit)?/:type(business_partner|location|country)?"
          render={({ match, location }) => {
            const action = match.params.action
            const imType = match.params.type as ImTypeEnum
            const id = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).id)
            if (action && (!id || isNaN(id))) {
              return <Redirect to="/review" />
            }
            return action ? <IMPage action="review" imType={imType} imId={id} /> : <ReviewPage />
          }}
        />
        <Route
          exact
          path="/history/:action(edit|copy)?/:type(business_partner|location|country)?"
          render={({ match, location }) => {
            const action = match.params.action
            const imType = match.params.type as ImTypeEnum
            const id = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).id)
            if (action && (!id || isNaN(id))) {
              return <Redirect to="/history" />
            }
            return action ? <IMPage action={action as ImAction} imType={imType} imId={id} /> : <HistoryPage />
          }}
        />
        <Route exact path="/business_partners" component={BusinessPartnersPage} />
        <Route exact path="/worldmap" component={WorldMapPage} />
        <Route exact path="/exclusion_list" component={ExclusionListPage} />
        <Route exact path="/shift_management" component={ShiftManagementPage} />
        <Route exact path="/individual_settings">
          <IndividualSettingsPage userSettings={initialData.userSettings} />
        </Route>
        <Route exact path="*">
          <Redirect to="/inbox" />
        </Route>
      </Switch>
    </IMContextProvider>
  )
}

export default RootPage
