import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
// todo: replace with useNavigation once the router is updated to version 6+
import { useHistory } from 'react-router-dom'

export const useClearDataOnLeavingReviewPage = (action = 'review') => {
  const history = useHistory()
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      if (action === 'review' && !history.location.pathname.includes('review')) {
        queryClient.removeQueries({
          queryKey: ['GET_REVIEW_DATA'],
        })
      }
    }
  }, [])
}
