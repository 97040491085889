import I18n from 'i18next'
import {
  ImTypeEnum,
  QueryStrategyValuesEnum,
  ValidityQueryValuesEnum,
  MessageTypeQueryValuesEnum,
  ImFilterPageNameEnum,
} from '@enums'

export const getSearchStrategyOptions = (page: ImFilterPageNameEnum) => [
  { value: QueryStrategyValuesEnum.ALL, label: I18n.t('im_filters.selects.search_strategy.options.all') },
  {
    value: QueryStrategyValuesEnum.SUBJECT_BODY,
    label: I18n.t('im_filters.selects.search_strategy.options.subject_body'),
  },
  ...(page === ImFilterPageNameEnum.HISTORY
    ? [
        {
          value: QueryStrategyValuesEnum.ID_IN_ADMIN,
          label: I18n.t('im_filters.selects.search_strategy.options.id_in_admin'),
        },
      ]
    : []),
]

export const getQueryMessageSubtypeOptions = () => [
  { value: ImTypeEnum.ALL, label: I18n.t('im_filters.selects.message_subtype.options.all') },
  {
    value: ImTypeEnum.BUSINESS_PARTNER,
    label: I18n.t('im_filters.selects.message_subtype.options.business_partner'),
  },
  { value: ImTypeEnum.LOCATION, label: I18n.t('im_filters.selects.message_subtype.options.location') },
  { value: ImTypeEnum.COUNTRY, label: I18n.t('im_filters.selects.message_subtype.options.country') },
]

export const getValidityOptions = () => [
  { value: ValidityQueryValuesEnum.ALL, label: I18n.t('im_filters.selects.validity.options.all') },
  { value: ValidityQueryValuesEnum.EXPIRE_TODAY, label: I18n.t('im_filters.selects.validity.options.expire_today') },
  { value: ValidityQueryValuesEnum.VALID, label: I18n.t('im_filters.selects.validity.options.valid') },
]

export const getMessageTypeOptions = () => [
  { value: MessageTypeQueryValuesEnum.ALL, label: I18n.t('im_filters.selects.message_type.options.all') },
  {
    value: MessageTypeQueryValuesEnum.INDICATOR_MESSAGE,
    label: I18n.t('im_filters.selects.message_type.options.indicator_message'),
  },
  { value: MessageTypeQueryValuesEnum.NEWS, label: I18n.t('im_filters.selects.message_type.options.news') },
]
