import { useStore, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useCreateIndicatorMessage, useGetIndicatorMessage, useReviewIndicatorMessage } from '@pages/im/api'
import { useIMContext } from '@hooks'
import { displayError } from '@services'
import { ImAction } from '@types'
import { ImTypeEnum } from '@enums'
import { useUnsaveHits } from '@api'
import { BPIMForm, LocationIMForm, CountryIMForm } from '../'

import { errorsSaving } from '../../actions/save'
import { buildPreloadedState } from '../../utils/build_preloaded_state'
import { setImInitState } from '../../actions'

type Props = {
  action: ImAction
  imType?: ImTypeEnum | null
  imId: number | null
  articleId: number | null
}

export const IMTypeSpecificForm = ({ action, imType, imId, articleId }: Props) => {
  const history = useHistory()
  const store = useStore()
  const dispatch = useDispatch()

  const { imFormValues, setIMFormValues, isValid } = useIMContext()

  const createIMQuery = useCreateIndicatorMessage()
  const reviewIMQuery = useReviewIndicatorMessage()
  const getIMQuery = useGetIndicatorMessage(action, imId)
  const unsaveHitsQuery = useUnsaveHits()

  const bundleId = getIMQuery?.data?.messageBundle?.id || null

  const handleError = () => {
    dispatch(errorsSaving())
  }

  const handleSubmit = async () => {
    if (!isValid) {
      displayError('IM form not valid !')
      return
    }

    if (imFormValues) {
      const state = store.getState().indicatorMessage

      switch (action) {
        case 'create':
        case 'copy': {
          const ok = await createIMQuery.mutateAsync({ state, imFormValues, articleId }).catch(handleError)
          if (ok) {
            const state = buildPreloadedState({})
            dispatch(setImInitState(state))
            setIMFormValues(null)

            history.push('/review')

            if (action === 'create') {
              unsaveHitsQuery.mutateAsync([String(articleId)])
            }
          }
          break
        }
        case 'review': {
          if (bundleId !== null) {
            const ok = await reviewIMQuery.mutateAsync({ state, imFormValues, bundleId }).catch(handleError)
            if (ok) {
              const state = buildPreloadedState({})
              dispatch(setImInitState(state))
              setIMFormValues(null)

              history.push('/review')
            }
          }
          break
        }
      }
    }
  }

  const handleCancel = () => {
    const state = buildPreloadedState({})
    dispatch(setImInitState(state))
    setIMFormValues(null)

    switch (action) {
      case 'review':
        return history.push('/review')
      case 'copy':
        return history.push('/history')
      default:
        history.push(articleId ? '/saved_hits' : '/inbox')
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  const isLoading = createIMQuery.isPending || reviewIMQuery.isPending

  switch (imType) {
    case ImTypeEnum.BUSINESS_PARTNER:
      return <BPIMForm saveMessage={handleSubmit} onCancel={handleCancel} onBack={handleBack} isLoading={isLoading} />

    case ImTypeEnum.LOCATION:
      return <LocationIMForm saveMessage={handleSubmit} onCancel={handleCancel} onBack={handleBack} history={history} />

    case ImTypeEnum.COUNTRY:
      return (
        <CountryIMForm
          saveMessage={handleSubmit}
          onCancel={handleCancel}
          onBack={handleBack}
          history={history}
          isEditing={false}
          onlyNews={imFormValues?.onlyNews || false}
        />
      )
  }
}
