import { Component } from 'react'
import { Tooltip, Box } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { isEmpty } from 'lodash'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import { businessPartner } from '@common/utils/shapes'

import * as styles from '../../bp-im-form.scss'

const t = (name) => I18n.t(`indicator_message.form.supplier.${name}`)

export default class SupplierSearchResult extends Component {
  static propTypes = {
    removeSupplier: PropTypes.func.isRequired,
    suppliers: PropTypes.arrayOf(businessPartner).isRequired,
    suppliersLoading: PropTypes.bool.isRequired,
    formType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }

  removeSupplier = (id) => {
    this.props.removeSupplier(id)
  }

  mapSupplier = ({ id, name, coreInstance, address: { streetAndNumber, city, country, zipcode } }) => {
    const { formType, disabled } = this.props
    const nodeId = `business_partner_${id}_${coreInstance}`
    return (
      <Box
        key={`${id}-${coreInstance}`}
        data-testid={formType + '_' + nodeId}
        sx={{
          cursor: 'default',
        }}
      >
        {!disabled && (
          <ClearIcon
            onClick={() => this.removeSupplier({ id, coreInstance })}
            fontSize="inherit"
            data-testid={`${formType}_remove_result`}
            sx={{ mb: '2px', cursor: 'pointer' }}
          />
        )}
        <Tooltip
          PopperProps={{
            container: () => document.getElementById(formType + '_form') ?? document.body, // use form cont to not trigger scroll on body
          }}
          title={
            <>
              {streetAndNumber}, {zipcode}
            </>
          }
        >
          <span>
            {name}, {city}, {country}
          </span>
        </Tooltip>
      </Box>
    )
  }

  render() {
    const { suppliers, suppliersLoading, disabled } = this.props
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          color: disabled ? 'text.disabled' : 'text.primary',
        }}
        className={`${styles.result} ${(suppliersLoading || isEmpty(suppliers)) && styles.resultCenter}`}
      >
        {suppliersLoading && t('info.loading')}
        {!suppliersLoading && (isEmpty(suppliers) ? t('info.no_results') : suppliers.map(this.mapSupplier))}
      </Box>
    )
  }
}
