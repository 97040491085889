import { useMemo, useRef, useCallback, useState } from 'react'
import { Box, Link, Stack, Autocomplete, TextField, CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import I18n from 'i18next'
import { Field, Input, Select, Toggle } from '@riskmethods/rm-front-end'

import { useIndicatorsRelatedDataMaps } from '@api'
import { ImFilterPageNameEnum } from '@enums'

import { initValues } from './constants'
import {
  getSearchStrategyOptions,
  getQueryMessageSubtypeOptions,
  getValidityOptions,
  getMessageTypeOptions,
} from './helpers'

type ImFiltersProps = {
  pageName: ImFilterPageNameEnum
  isLoading: boolean
  submitPossibleOnLoading?: boolean
  changeFilterValue: (
    name: string,
    value: unknown,
    config?: {
      shouldValidate: boolean
      shouldDirty: boolean
      shouldTouch: boolean
    }
  ) => void
  reset: (
    values?: Partial<Record<string, unknown>>,
    keepStateOptions?:
      | Partial<{
          keepDirtyValues: boolean
          keepErrors: boolean
          keepDirty: boolean
          keepValues: boolean
          keepDefaultValues: boolean
          keepIsSubmitted: boolean
          keepTouched: boolean
          keepIsValid: boolean
          keepSubmitCount: boolean
        }>
      | undefined
  ) => void
  selectedIndicatorId: string | null
}

export const ImFilters = ({
  pageName,
  changeFilterValue,
  reset,
  selectedIndicatorId,
  isLoading,
  submitPossibleOnLoading,
}: ImFiltersProps) => {
  const [isModifiedAfterSubmit, setIsModifiedAfterSubmit] = useState(false)
  const {
    data: { indicatorsDataMap },
    isLoading: isIndicatorDataLoading,
  } = useIndicatorsRelatedDataMaps()
  const submitBtn = useRef<HTMLButtonElement>(null)
  const [localIndicatorId, setLocalIndicatorId] = useState(selectedIndicatorId)
  const showLoadingIndicatorOnSubmitBtn = isLoading && (submitPossibleOnLoading ? !isModifiedAfterSubmit : true)

  const uniqueIndicators = useMemo(() => {
    if (!indicatorsDataMap) {
      return []
    }

    return [...indicatorsDataMap.values()]
  }, [indicatorsDataMap])

  const selectedIndicator = useMemo(() => {
    if (!localIndicatorId || !indicatorsDataMap) {
      return null
    }
    return indicatorsDataMap.get(localIndicatorId)
  }, [indicatorsDataMap, localIndicatorId])

  const resetValues = useCallback(
    (e) => {
      e.preventDefault()
      reset(initValues[pageName])
      setLocalIndicatorId(initValues[pageName].indicator_id)
      submitPossibleOnLoading && setIsModifiedAfterSubmit(true)
    },
    [reset, initValues, pageName]
  )

  const onValueChange = useCallback(
    (name: string) => (value) => {
      submitPossibleOnLoading && setIsModifiedAfterSubmit(true)
      changeFilterValue(name, value)
    },
    [changeFilterValue, submitPossibleOnLoading, setIsModifiedAfterSubmit]
  )

  const onAfterSubmit = useCallback(() => {
    submitPossibleOnLoading && setTimeout(() => setIsModifiedAfterSubmit(false), 0)
  }, [setIsModifiedAfterSubmit])

  const saveValueAndSubmitFormOnEnter = useCallback(
    (e) => {
      // preventing default behavior - firing first submit(here - reset filters) feedback on pressing enter
      if (e.key === 'Enter') {
        e.preventDefault()
        submitBtn.current?.click()
      }
    },
    [submitBtn.current]
  )

  return (
    <Box
      data-testid={`${pageName}_filters`}
      position="sticky"
      top={0}
      m={1}
      zIndex={2}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Stack direction="row" flexWrap="wrap" alignItems="flex-end" gap={1.25}>
        <Box width="350px">
          <Field
            label={I18n.t('im_filters.inputs.keywords.label')}
            name="keywords"
            Component={(props) => (
              <Input
                {...props}
                testId="keywords_input"
                textFieldProps={{
                  placeholder: I18n.t('im_filters.inputs.keywords.placeholder'),
                  onKeyDown: saveValueAndSubmitFormOnEnter,
                }}
                onChange={onValueChange('keywords')}
                enableClearBtn
              />
            )}
          />
        </Box>
        <Box width="180px">
          <Field
            label={I18n.t('im_filters.selects.search_strategy.label')}
            name="query_strategy"
            Component={(props) => (
              <Select
                {...props}
                testId="query_strategy_select"
                options={getSearchStrategyOptions(pageName)}
                onChange={onValueChange('query_strategy')}
              />
            )}
          />
        </Box>
        <Box width="180px">
          <Field
            label={I18n.t('im_filters.selects.message_subtype.label')}
            name="message_subtype"
            Component={(props) => (
              <Select
                {...props}
                testId="message_subtype_select"
                options={getQueryMessageSubtypeOptions()}
                onChange={onValueChange('message_subtype')}
              />
            )}
          />
        </Box>
        <Box width="180px">
          <Field
            label={I18n.t('im_filters.selects.validity.label')}
            name="validity"
            Component={(props) => (
              <Select
                {...props}
                testId="validity_select"
                options={getValidityOptions()}
                onChange={onValueChange('validity')}
              />
            )}
          />
        </Box>
        <Box width="180px">
          <Field
            label={I18n.t('im_filters.selects.message_type.label')}
            name="message_type"
            Component={(props) => (
              <Select
                {...props}
                testId="message_type_select"
                options={getMessageTypeOptions()}
                onChange={onValueChange('message_type')}
              />
            )}
          />
        </Box>
        <Box width="200px">
          <Field
            label={I18n.t('im_filters.selects.indicator.label')}
            name="indicator_id"
            Component={(props) => (
              <Autocomplete
                data-testid="indicator_id_select"
                sx={{ width: 200 }}
                options={uniqueIndicators}
                getOptionLabel={(item) => item.name}
                isOptionEqualToValue={(option, value) => option.rmCode === value.rmCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={I18n.t('im_filters.selects.indicator.placeholder')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isIndicatorDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                {...props}
                onChange={(_, newValue) => {
                  setLocalIndicatorId(newValue ? newValue.rmCode : null)
                  submitPossibleOnLoading && setIsModifiedAfterSubmit(true)
                  changeFilterValue?.('indicator_id', newValue ? newValue.rmCode : null)
                }}
                value={selectedIndicator}
              />
            )}
          />
        </Box>
        {pageName === ImFilterPageNameEnum.HISTORY && (
          <>
            <Box width="110px">
              <Field
                name="from_last_two_years"
                Component={(props) => (
                  <Toggle
                    {...props}
                    label={I18n.t('im_filters.checkboxes.from_last_two_years.label')}
                    testId="from_last_two_years_check"
                    onChange={onValueChange('from_last_two_years')}
                  />
                )}
              />
            </Box>
          </>
        )}
        <Box>
          <Link component="button" type="button" onClick={resetValues} sx={{ mr: 1.5, lineHeight: '38px' }}>
            {I18n.t('im_filters.links.reset')}
          </Link>
        </Box>
        <Box>
          <LoadingButton
            id="inbox-filters-form-submit-btn"
            ref={submitBtn}
            type="submit"
            variant="contained"
            color="secondary"
            data-testid="search"
            loading={showLoadingIndicatorOnSubmitBtn}
            sx={{ whiteSpace: 'nowrap' }}
            onClick={onAfterSubmit}
          >
            {I18n.t('im_filters.buttons.search')}
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  )
}

export default ImFilters
