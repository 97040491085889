export const MIN_REQ_TIME_MS = 350
export const REQ_TIMEOUT_MS = 60 * 1000

const NODE_ENV = process.env.NODE_ENV

export const fetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  minReqTimeMs = MIN_REQ_TIME_MS
): Promise<Response> => {
  const timestamp = Date.now()
  const abortController = new AbortController()
  const signal = init?.signal || abortController.signal

  const timeoutId = setTimeout(() => {
    abortController.abort()
  }, REQ_TIMEOUT_MS)

  const { accessToken } = JSON.parse(String(localStorage.getItem('ric.user') || '') || '{}')

  const res = await global.fetch(input, {
    ...(init || {}),
    signal,
    credentials: 'same-origin',
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  clearTimeout(timeoutId)

  if (NODE_ENV !== 'test') {
    if (minReqTimeMs > 0 && Date.now() - timestamp < minReqTimeMs) {
      await new Promise((resolve) => setTimeout(resolve, minReqTimeMs - (Date.now() - timestamp)))
    }
  }

  if (res?.status === 401) {
    throw new Error('Unauthorized')
  }

  return res
}
