import { IndicatorsData, IndicatorSubtype } from '@types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetch } from '@services'
import { camelizeKeys } from 'humps'
import { useGetIndicators } from '@api'

const convertAllIndicatorsDataToIndicatorsSubtypesMap = (indicators: IndicatorsData) => {
  const indicatorsSubtypesMap = new Map()
  indicators.forEach((indicatorData) => {
    indicatorData?.subtypes?.forEach((indicatorSubtypesData: IndicatorSubtype) => {
      indicatorsSubtypesMap.set(indicatorSubtypesData.code, indicatorSubtypesData)
    })
  })

  return indicatorsSubtypesMap
}
const QUERY_KEY = 'USE_GET_SUB_INDICATOR_MAP'

const useIndicatorSubtypesMap = () => {
  const { data, isSuccess } = useGetIndicators()
  const queryClient = useQueryClient()
  const cachedIndicatorSubtypesMap = queryClient.getQueryData<Map<string, IndicatorSubtype>>([QUERY_KEY])

  return useQuery<Map<string, IndicatorSubtype> | null>({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      if (cachedIndicatorSubtypesMap) {
        return cachedIndicatorSubtypesMap
      }

      let indicators: IndicatorsData | null = null
      if (data && isSuccess) {
        indicators = data
      } else {
        const res = await fetch('/api/indicators')
        if (res.ok) {
          const data = await res.json()
          indicators = data ? camelizeKeys(data).indicators : null
        }
      }

      return indicators ? convertAllIndicatorsDataToIndicatorsSubtypesMap(indicators) : null
    },
    enabled: isSuccess || !cachedIndicatorSubtypesMap,
  })
}

export default useIndicatorSubtypesMap
