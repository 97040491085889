import { useState, useEffect, useMemo } from 'react'
import { Autocomplete, Chip, TextField, CircularProgress } from '@mui/material'
import { debounce } from 'lodash'
import I18n from 'i18next'

import { getJSON } from '@common/http'

const t = (name) => I18n.t(`indicator_message.placeholders.${name}`)

const getUniqueCities = (cities) =>
  cities.reduce((acc, curr) => {
    if (acc.every((city) => city.name !== curr.name)) {
      acc.push(curr)
    }

    return acc
  }, [])

const MIN_QUERY_LENGTH = 3
const MIN_QUERY_TEXT = `Type at least ${MIN_QUERY_LENGTH} characters`
export const FETCH_CITIES_WAIT_TIME = 1500

const citiesCache = new Map()

const fetchCities = debounce(async (query = '', cb) => {
  if (citiesCache.has(query)) {
    return cb({
      cities: citiesCache.get(query),
    })
  }

  const response = await getJSON('/api/clusters/cities', { query })
    .then(({ data: { cities } }) => {
      const mappedCities = cities.map((city) => ({ name: city }))
      citiesCache.set(query, mappedCities)

      return {
        cities: mappedCities,
      }
    })
    .catch((error) => ({ error }))
  cb(response)
}, FETCH_CITIES_WAIT_TIME)

const CitiesSelect = ({ value, formType, onChange, disabled = false }) => {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [error, setError] = useState()

  const trimmedQuery = query.trim()
  useEffect(() => {
    if (!open || !trimmedQuery || trimmedQuery.length < MIN_QUERY_LENGTH) {
      setIsLoading(false)
      setOptions([])
      return
    }

    let active = true

    ;(async () => {
      setIsLoading(true)
      setError()
      fetchCities(trimmedQuery, (response) => {
        if (!active) {
          return
        }
        setIsLoading(false)

        if (response.error) {
          setError(response.error.message || 'Invalid cities data')
          setOpen(false)
          setOptions([])
          return
        }
        setOptions(response.cities || [])
      })
    })()

    return () => {
      active = false
    }
  }, [open, trimmedQuery])

  const processedOptions = useMemo(() => {
    const currentValueList = value ?? []
    const processedValueList = currentValueList.filter(Boolean)
    return getUniqueCities([...options, ...processedValueList].filter(Boolean))
  }, [options, value])

  const handleClose = (ev, reason) => {
    if (reason === 'selectOption') {
      return
    }

    setQuery('')
    setOpen(false)
  }

  return (
    <Autocomplete
      multiple
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      filterOptions={(options) => {
        if (query.length < MIN_QUERY_LENGTH) {
          return [{ name: MIN_QUERY_TEXT }]
        }
        return options
      }}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={({ name }) => name}
      options={processedOptions}
      loading={isLoading}
      onChange={(ev, value) => onChange(value)}
      value={value}
      data-testid={`${formType}_cities_select`}
      clearIcon={isLoading ? <></> : undefined}
      inputValue={query}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset') {
          return
        }
        setQuery(newInputValue)
      }}
      getOptionDisabled={(option) => option.name === MIN_QUERY_TEXT}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            size="small"
            sx={{ '&.MuiChip-sizeSmall': { my: '1px' } }}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            backgroundColor: 'background.default',
          }}
          name={`${formType}_cities`}
          label={t('cities')}
          error={!!error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && (
                  <CircularProgress color="inherit" size={20} sx={{ position: 'absolute', right: '37px' }} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default CitiesSelect
