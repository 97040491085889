import { useMutation, useQueryClient } from '@tanstack/react-query'

import { fetch } from '@services'
import { type GetIndicatorsData } from '@pages/shift-management/components/delay_hits/api'
import type { IndicatorToggleAction, ShiftIndicator, IndicatorsCollectionKey } from '@pages/shift-management/types'

type Data = ShiftIndicator | null

type Variables = {
  collectionKey: IndicatorsCollectionKey
  indicator: string
  action: IndicatorToggleAction
}

export const useUpdateIndicator = () => {
  const queryClient = useQueryClient()

  const updateIndicator = async ({ collectionKey, indicator, action }: Variables): Promise<Data> => {
    const res = await fetch(`/api/v2/buckets/${action}?indicator=${indicator}`, {
      method: 'POST',
    })

    let updatedIndicator: ShiftIndicator

    if (res.ok) {
      updatedIndicator = await res.json()

      queryClient.setQueryData(['SHIFT_MANAGEMENT_GET_INDICATORS'], (data: GetIndicatorsData) => {
        const collection = data?.[collectionKey]

        return {
          ...data,
          [collectionKey]: collection?.map((item) => {
            if (item.indicator === updatedIndicator.indicator) {
              return updatedIndicator
            }
            return item
          }),
        }
      })

      return updatedIndicator
    }

    return null
  }

  return useMutation<Data, unknown, Variables>({
    mutationKey: ['SHIFT_MANAGEMENT_UPDATE_INDICATOR'],
    mutationFn: updateIndicator,
    gcTime: 0,
  })
}
