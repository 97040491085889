import Validator from 'validatorjs'
import I18n from 'i18next'
import { LocationDataToValidate, LocationValidationRules, ImValidationResult, IndicatorMessageFormValues } from '@types'
import { isIndicatorSubtypesValueValid } from './indicator_subtypes'

export const validationLocation = (
  { radius, selectedLocations, polygonPoints, isPolygon }: LocationDataToValidate,
  { selectedIndicator, indicatorSubtypes }: IndicatorMessageFormValues
): ImValidationResult => {
  const indicatorId = selectedIndicator && selectedIndicator.rmCode
  const indicatorSubtypesValid = isIndicatorSubtypesValueValid(selectedIndicator, indicatorSubtypes)
  const rules: LocationValidationRules = {
    radius: isPolygon ? [] : 'required|min:0|max:500',
    indicator: 'string|required',
    selectedLocations: 'required',
    polygonPoints: isPolygon ? 'required|min:3' : [],
  }

  const messages = {
    required: I18n.t('common.messages.validation.required'),
    numeric: I18n.t('common.messages.validation.numeric'),
    min: I18n.t('common.messages.validation.location.min_max_radius'),
    max: I18n.t('common.messages.validation.location.min_max_radius'),
    'min.polygonPoints': I18n.t('common.messages.validation.location.min_polygon_points'),
    'string.indicator': I18n.t('common.messages.validation.location.incorrect_indicator'),
  }

  const validator = new Validator(
    { radius, indicator: indicatorId, selectedLocations, polygonPoints, isPolygon },
    rules,
    messages
  )

  const isValid = !validator.fails() && indicatorSubtypesValid

  return isValid
    ? { valid: true, errors: {} }
    : {
        valid: false,
        errors: {
          ...validator.errors.all(),
          ...(indicatorSubtypesValid
            ? {}
            : {
                noIndicatorSubtypes: I18n.t('common.messages.validation.required'),
              }),
        },
      }
}
