import { useIndicatorsRelatedDataMaps } from '@api'
import { Bundle } from '@types'
import { showMoreFormatter } from '@common/table_formatters/show_more_formatter/show_more_formatter'

export const indicatorSubtypesFormatter = (_, bundle: Bundle, _rowIndex: number, formatExtraData) => (
  <IndicatorFormatted
    indicatorSubtypes={bundle?.indicatorSubtypes ?? []}
    rowIndex={_rowIndex}
    formatExtraData={formatExtraData}
  />
)

const IndicatorFormatted = ({
  indicatorSubtypes,
  rowIndex,
  formatExtraData,
}: {
  indicatorSubtypes: string[]
  rowIndex: number
  formatExtraData
}) => {
  const {
    data: { indicatorsSubtypesMap },
  } = useIndicatorsRelatedDataMaps()
  const indicatorSubtypesNames = indicatorSubtypes.length
    ? indicatorSubtypes.flatMap((subtypeCode) => indicatorsSubtypesMap?.get(subtypeCode)?.name ?? [])
    : []
  return <>{indicatorSubtypes.length ? showMoreFormatter(indicatorSubtypesNames, rowIndex, formatExtraData) : '-'}</>
}
