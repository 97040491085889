import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fetch, displayError } from '@services'
import { HistoryMessageRecipients, HistoryMessageRecipientsSchema, HistoryMessagesData } from '@types'
import { joinRecipients } from '@common/entities/messages/utils'
import { camelizeKeys } from 'humps'

const useGetRecipients = () => {
  const queryClient = useQueryClient()

  const mutationFn = async (message_id: number) => {
    const res = await fetch(
      '/api/messages/recipients.json',
      {
        method: 'POST',
        body: JSON.stringify({ message_id }),
      },
      0
    )
    const data: { messages_recipients: HistoryMessageRecipientsSchema[] } = await res.json()

    if (!res.ok) {
      setTimeout(() => {
        displayError('Could not get Recipients this time.')
      })
      return Promise.reject()
    }
    const messageRecipients = camelizeKeys(data.messages_recipients) as HistoryMessageRecipients[]
    await queryClient.setQueryData(['GET_HISTORY_MESSAGES'], (data: HistoryMessagesData): HistoryMessagesData => {
      return {
        ...data,
        messages: joinRecipients(data.messages, messageRecipients),
      }
    })
  }
  return useMutation<void, void, number>({
    mutationKey: ['GET_MESSAGE_RECIPIENTS'],
    mutationFn,
  })
}

export default useGetRecipients
