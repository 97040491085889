import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fetch, displayError } from '@services'
import { useSetFreezedBundle, useUnsetFreezedBundle } from '@pages/review/api'
import i18n from '@src/client/i18n'

const useDeleteBundle = () => {
  const queryClient = useQueryClient()
  const { mutate: setFreezedBundle } = useSetFreezedBundle()
  const { mutate: unsetFreezedBundle } = useUnsetFreezedBundle()

  const mutationFn = async (bundleId: number): Promise<void> => {
    setFreezedBundle(bundleId)
    const res = await fetch(
      `/api/message_bundles/${bundleId}.json`,
      {
        method: 'DELETE',
      },
      0
    )

    if (!res.ok) {
      setTimeout(() => {
        displayError(i18n.t('reviews.errors.deletion_failure'))
      })
      unsetFreezedBundle()
      return Promise.reject()
    }

    await queryClient.fetchQuery({ queryKey: ['GET_REVIEW_DATA'] })
  }
  return useMutation<void, void, number>({
    mutationKey: ['DELETE_MESSAGE_BUNDLE'],
    mutationFn,
  })
}

export default useDeleteBundle
