import { connect } from 'react-redux'
import { Box, LinearProgress } from '@mui/material'
import { useIsFetching } from '@tanstack/react-query'

import { useAppContext } from '@src/client/hooks'

type Props = {
  loading: boolean
}

const AppLoader = ({ loading }: Props) => {
  const { isLoading } = useAppContext()

  const isFetching = useIsFetching()

  return (
    <Box position="sticky" top={0} zIndex={3} sx={{ height: '2px', backgroundColor: 'background.paper' }}>
      {loading || isLoading || isFetching ? <LinearProgress data-testid="loading_bar" sx={{ height: '2px' }} /> : null}
    </Box>
  )
}

const mapStateToProps = ({ bp, elist, shiftManagement, worldMap }) => {
  return {
    loading: bp.table.loading || elist.table.loading || shiftManagement.loading || worldMap.loading.isLoading,
  }
}

export default connect(mapStateToProps)(AppLoader)
