import { some, includes, isEmpty, pickBy } from 'lodash'
import I18n from 'i18next'
import { ImFormSupplierData, ImValidationResult, IndicatorMessageFormValues } from '@types'
import { imValidationValidResult } from '../constants'
import { isIndicatorSubtypesValueValid } from './indicator_subtypes'

const t = (name: string) => I18n.t(`common.messages.validation.${name}`)

const validateOnlyNews = (
  { newsForm }: ImFormSupplierData
  // todo: uncomment when BP news indicator soulf be added
  // imFormValues: IndicatorMessageFormValues
): ImValidationResult => {
  const noNewsSuppliers = isEmpty(newsForm.suppliers)
  /*
   * todo: uncomment it and validation messages when you need to add indicators to bp news
   *
   * const noIndicator = isEmpty(imFormValues.selectedIndicator)
   * const noIndicatorSubtypes = !isIndicatorSubtypesValueValid(
   *   imFormValues.selectedIndicator,
   *   imFormValues.indicatorSubtypes
   *  )
   *  const valid = [noIndicator, noNewsSuppliers, noIndicatorSubtypes].some(invalid => invalid);
   */
  const valid = !noNewsSuppliers
  return valid
    ? imValidationValidResult
    : {
        valid,
        errors: {
          ...(noNewsSuppliers && { noNewsSuppliers: t('supplier.suppliers_empty') }),
          //...(noIndicator && { noIndicator: t('required') }),
          //...(noIndicatorSubtypes && { noIndicatorSubtypes: t('required') }),
        },
      }
}

const validateIMAndNews = (
  { alertForm, newsForm }: ImFormSupplierData,
  imFormValues: IndicatorMessageFormValues
): ImValidationResult => {
  const noAlertSuppliers = isEmpty(alertForm.suppliers)
  const noIndicator = isEmpty(imFormValues.selectedIndicator)
  const noIndicatorSubtypes = !isIndicatorSubtypesValueValid(
    imFormValues.selectedIndicator,
    imFormValues.indicatorSubtypes
  )
  const newsSuppliersStringified = newsForm.suppliers.map((newsSupplier) => JSON.stringify(newsSupplier))
  const sameSupplierExists = some(alertForm.suppliers, (alertSupplier) =>
    includes(newsSuppliersStringified, JSON.stringify(alertSupplier))
  )
  const bankruptcyWithValidityDate =
    !noIndicator && !imFormValues.noValidity && alertForm.selectedIndicator?.eventType === 'bankruptcy'
  const isValid = ![
    noAlertSuppliers,
    sameSupplierExists,
    noIndicator,
    bankruptcyWithValidityDate,
    noIndicatorSubtypes,
  ].some((message) => !!message)

  return isValid
    ? imValidationValidResult
    : ({
        valid: false,
        errors: pickBy({
          noAlertSuppliers: noAlertSuppliers && t('supplier.suppliers_empty'),
          sameSupplierExists: sameSupplierExists && t('supplier.same_supplier_exists'),
          noIndicator: noIndicator && t('required'),
          noIndicatorSubtypes: noIndicatorSubtypes && t('required'),
          bankruptcyWithValidityDate: bankruptcyWithValidityDate && t('supplier.bankruptcy_with_validity_date'),
        }),
      } as ImValidationResult)
}

export const validationSupplier = (supplierData, imFormValues: IndicatorMessageFormValues): ImValidationResult => {
  return imFormValues.onlyNews
    ? // todo: uncomment when BP news indicator soulf be added
      // ? validateOnlyNews(supplierData, imFormValues)
      validateOnlyNews(supplierData)
    : validateIMAndNews(supplierData, imFormValues)
}
