import I18n from 'i18next'

import dateComparator from '@common/utils/date_comparator'
import { geometryFormatter } from '@common/table_formatters/geometry_formatter'
import { handleEmptyFormatter } from '@common/table_formatters/handle_empty_formatter'
import { readMoreFormatter } from '@common/table_formatters/read_more_formatter'
import { showMoreFormatter } from '@common/table_formatters/show_more_formatter/show_more_formatter'
import { industriesFormatter } from '@common/table_formatters/industriesFormatter'
import { actionsFormatter } from '@pages/history/components/formatters/actions_formatter'
import { indicatorSubtypesFormatter } from '@common/table_formatters/indicator_subtypes_formatter'
import { nameFormatter } from '../components/formatters/name_formatter'
import { wordsBreakFormatter } from '@src/client/common/table_formatters/styles'

const buildTableColumns = (props) => [
  {
    dataField: 'coreMessageId',
    text: I18n.t('common.messages_table.columns.id_in_admin'),
    attrs: (id) => ({
      'data-testid': id,
    }),
  },
  {
    dataField: 'subject',
    text: I18n.t('common.messages_table.columns.subject'),
  },
  {
    dataField: 'sourceContent',
    text: I18n.t('common.messages_table.columns.source'),
    formatter: wordsBreakFormatter,
  },
  {
    dataField: 'body',
    text: I18n.t('common.messages_table.columns.body'),
    formatter: readMoreFormatter('hyperlinks[0]'),
  },
  {
    dataField: 'name',
    text: I18n.t('common.messages_table.columns.name'),
    formatter: nameFormatter,
  },
  {
    dataField: 'indicatorSubtypes',
    text: I18n.t('common.messages_table.columns.indicator_subtypes'),
    formatter: indicatorSubtypesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'targets.names',
    text: I18n.t('common.messages_table.columns.target'),
    formatter: (_, items, rowIndex, formatExtraData) =>
      showMoreFormatter(
        items.targets.map((t) => t.name),
        rowIndex,
        formatExtraData
      ),
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
    },
  },
  {
    dataField: 'targets.geometry',
    text: I18n.t('common.messages_table.columns.area'),
    formatter: geometryFormatter,
  },
  {
    dataField: 'industries',
    text: I18n.t('common.messages_table.columns.industries'),
    formatter: industriesFormatter,
    formatExtraData: {
      handleRowExpanded: props.handleRowExpanded,
      expanded: props.expanded,
      industryMap: props.industryMap,
    },
  },
  {
    dataField: 'reviewedAt',
    text: I18n.t('common.messages_table.columns.approved_and_created_on'),
    sort: true,
    sortFunc: dateComparator,
    formatter: (_, row) => `${row.reviewedAt} / ${row.createdAt}`,
  },
  {
    dataField: 'approvedAndCreatedBy',
    text: I18n.t('common.messages_table.columns.approved_and_created_by'),
    formatter: (_, row) => `${row.reviewedBy} / ${row.createdBy}`,
  },
  {
    dataField: 'validUntil',
    text: I18n.t('common.messages_table.columns.valid_until'),
    sort: true,
    sortFunc: dateComparator,
    formatter: handleEmptyFormatter,
  },
  {
    dataField: 'actions',
    text: I18n.t('common.messages_table.columns.action'),
    formatter: actionsFormatter,
    formatExtraData: {
      rowEvents: props.rowEvents,
      history: props.history,
    },
    headerAlign: 'center',
  },
]

export default buildTableColumns
