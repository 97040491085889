import { useSearchParams, Form } from '@riskmethods/rm-front-end'
import { useQueryClient } from '@tanstack/react-query'

import { PageCont, TopCont, BottomCont } from '@common/layout'
import { ImFilters } from '@components'
import { HistoryFormValues } from '@types'
import { ImFilterPageNameEnum } from '@enums'
import { useHistoryMessages } from '@pages/history/api'

import { DEFAULT_DATA as GET_HISTORY_MESSAGES_DEFAULT_DATA } from './api/use-get-history-messages/use-get-history-messages'
import Table from './components/table'
import { initFilterConfig } from './utils/constants'
import { useCallback, useEffect } from 'react'

const HistoryPage = () => {
  const { searchParams, syncSearchParams } = useSearchParams(initFilterConfig)
  const getHistoryMessages = useHistoryMessages(searchParams.object as HistoryFormValues)
  const { indicator_id } = searchParams.object as HistoryFormValues
  const queryClient = useQueryClient()

  const fetchHistoryMessages = useCallback(async () => {
    await getHistoryMessages.refetch(searchParams.object)
    window.scrollTo(0, 0)
  }, [getHistoryMessages])

  const handleFiltersFormSubmit = async (data: object) => {
    await syncSearchParams({ ...data, page: 1 }, false)
    fetchHistoryMessages()
  }

  const handlePageChanged = async (page) => {
    await syncSearchParams({ ...searchParams.object, page }, false)
    fetchHistoryMessages()
  }

  const handeSortChanged = async (sort) => {
    await syncSearchParams({ ...searchParams.object, sort, page: 1 }, false)
    fetchHistoryMessages()
  }

  useEffect(() => {
    fetchHistoryMessages()

    return () => {
      queryClient.cancelQueries({
        queryKey: ['GET_HISTORY_MESSAGES'],
      })
      queryClient.setQueryData(['GET_HISTORY_MESSAGES'], () => {
        return GET_HISTORY_MESSAGES_DEFAULT_DATA
      })
    }
  }, [])

  return (
    <div data-testId="history-page-wrapper">
      <Form config={{ defaultValues: searchParams.object }} onSubmit={handleFiltersFormSubmit}>
        {(formContext) => {
          const { reset, setValue } = formContext

          return (
            <PageCont>
              <TopCont>
                <ImFilters
                  pageName={ImFilterPageNameEnum.HISTORY}
                  reset={reset}
                  changeFilterValue={setValue}
                  selectedIndicatorId={indicator_id}
                  isLoading={getHistoryMessages.isFetching}
                  submitPossibleOnLoading
                />
              </TopCont>
              <BottomCont>
                <Table
                  data={getHistoryMessages.data}
                  handlePageChanged={handlePageChanged}
                  handleSortChanged={handeSortChanged}
                  loading={getHistoryMessages.isFetching}
                  error={getHistoryMessages.error}
                />
              </BottomCont>
            </PageCont>
          )
        }}
      </Form>
    </div>
  )
}

export default HistoryPage
